/**
 * Module API
 */

import * as validation from './validation'

export { default as configureStore, store } from './store/configure'
export { getModule } from './modules'
export { connection as socketConnection } from './sockets'
export { validation }
export { default as request } from './request'

// NOTE this is convenient so we can mock out request actions which use the
// REQUEST symbol as the key. Without having access to this value, it's hard for
// us to intercept request actions and mock out the response
export { REQUEST } from './middleware/request'
