// NOTE these are global helpers which may used on multiple projects
export { getAuditEntryDetails } from './get-audit-entry-details'
export { getAuditItemsData } from './get-audit-items-data'
export { buildFetchUrl } from './build-fetch-url'
export { fetchImage, validateBase64Image } from './fetch-image'
export { getAssigneesFullNames } from './get-assignees-full-names'
export { getGeometryGeocodedString } from './get-geometry-geocoded-string'
export { getIssueDetails } from './get-issue-details'
export { getJobDetails } from './get-job-details'
export { getLocationReference } from './get-location-reference'
export { getPrettyDuration } from './get-pretty-duration'
export { getReferenceDetails } from './get-reference-details'
export { getStatusDetails } from './get-status-details'
export { getTaskEntryDetails } from './get-task-entry-details'
export { getTimezoneAbbr } from './get-timezone-abbr'
export { getTimezoneDatetime } from './get-timezone-datetime'
export { getUserFullName } from './get-user-full-name'
export { getValueAsPercentage } from './get-value-as-percentage'
