export enum BatchState {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export type BatchPayload = Record<string, unknown>

export interface BatchItem {
  id: string
  payload: BatchPayload
  state: BatchState
  error?: Error
}

export interface Batch {
  id: string
  label: string
  items: BatchItem[]
  state: BatchState
  processingFn: (payload: BatchPayload) => Promise<void>
}

export interface BatchRequest {
  id: string
  label: string
  payloads: BatchPayload[]
  processingFn: (payload: BatchPayload) => Promise<void>
}
