import { defaults, isArray, isEmpty, join } from 'lodash'

const DEFAULT_LOGGLY_DOMAIN = 'logs-01.loggly.com'

export default function LighthouseLoggly(options = {}) {
  const {
    defaultProps = {},
    domain = DEFAULT_LOGGLY_DOMAIN,
    fetch,
    key,
    protocol = 'https',
    tags,
  } = options

  if (!fetch) {
    throw new Error(
      'LighthouseLoggly must be initialized with a fetch function',
    )
  }

  if (!key) {
    throw new Error('LighthouseLoggly must be initialized with a Loggly key')
  }

  if (!isArray(tags) || isEmpty(tags)) {
    throw new Error(
      'LighthouseLoggly must be initialized with a Loggly tags array',
    )
  }

  this.defaultProps = defaultProps
  this.domain = domain
  this.key = key
  this.fetch = fetch
  this.protocol = protocol
  this.tags = tags
}

LighthouseLoggly.prototype = {
  send(data) {
    const { domain, fetch, key, protocol, tags } = this

    const tagsString = join(tags, ',')
    const url = `${protocol}://${domain}/inputs/${key}/tag/${tagsString}`

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(data),
    }

    return fetch(url, options)
  },

  track(level, data) {
    const type = typeof data

    if (!data || !(type === 'object' || type === 'string')) return
    const metadata =
      type === 'string'
        ? {
            message: data,
          }
        : data

    return this.send(
      defaults(
        {},
        this.defaultProps,
        {
          level,
        },
        metadata,
      ),
    )
  },

  debug(data) {
    return this.track('debug', data)
  },

  info(data) {
    return this.track('info', data)
  },

  warn(data) {
    return this.track('warn', data)
  },

  error(data) {
    return this.track('error', data)
  },

  critical(data) {
    return this.track('critical', data)
  },
}
