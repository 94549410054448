/**
 * NOTE We have two 🙈 'App' components! This one and another in the ./app
 * directory. This is the more modern implementation of an 'App' component and
 * we should gradually migrate from the other legacy component, which is a bit
 * messy.
 */

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { configureStore } from '@lighthouse/sdk'
import { Provider, Store } from 'react-redux'
import { Style } from 'radium'
import { reducer as formReducer } from 'redux-form'
import localForage from 'localforage'
import React, { useEffect, useState } from 'react'
import './config/locales'

import * as logger from './utils/logger'
import clickTrackingFn from './tracking'
import loggly from './utils/loggly'
import './setup'

import Login from './routes/authentication/login'
import Logout from './routes/authentication/logout'
import PrivateRoute from './routes/private'
import ResetPassword from './routes/authentication/reset-password'
import Signup from './routes/authentication/signup'
import LoginCallback from './routes/authentication/login-callback'

import LegacyApp from './app/index.js'
import { useAuth } from 'components/useAuth'
import { useMount, useUnmount } from 'react-use'
import PageLoader from 'components/page-loader'

// css
import resetStyles from './styles'
import LanguageProvider from 'components/languageProvider'
import NiceModal from '@ebay/nice-modal-react'
import { CacheProvider } from 'components/useCache'
import { useHistory } from 'react-router-dom'

const initialState = {}
const isDev = process.env.NODE_ENV === 'development'

export function App(): JSX.Element {
  const [initialising, setInitialising] = useState(true)
  const [store, setStore] = useState<Store>()
  const { authorize, getAccessToken } = useAuth()

  async function getAuthorization() {
    // NOTE only apps that have an oidc integration will be returned an
    // accessToken here. Legacy authorization will return undefined and fallback
    // to the (legacy) accessToken stored in redux state

    try {
      const accessToken = await getAccessToken()
      if (!accessToken) return

      return `bearer ${accessToken}`
    } catch (err) {
      // NOTE If we throw an error it indicates we couldn't get a token or
      // refresh it, so prompt user to re-authorize
      const isNetworkError = /(connection error|network error)/i.test(
        err.message
      )

      // NOTE Do not try an re-authorize for network errors. These can be
      // intermittent issues, so we want these to be handled within the request
      // middleware (sdk-js)
      if (!isNetworkError) {
        console.debug('App: re-authorize attempt...')
        await authorize()
        console.debug('App: re-authorize success!')
      }

      // NOTE Always throw the error, even after re-authorizing. This will be
      // caught and handle in the request middleware
      throw err
    }
  }

  useMount(() => {
    const storeConfig = {
      clickTrackingFn,
      storageAdapter: localForage,
      logger: !isDev && loggly,
      reduxLogger: console,
      reduxLoggerPredicate: () => process.env.REDUX_LOGGER_ENABLED === 'true',
      reducers: {
        form: formReducer,
      },
      requestOptions: {
        getAuthorization,
        retryOpts: {
          // NOTE Multiple retries makes more sense on mobile where network is
          // unreliable. In a browser, it makes more sense to fail sooner so we only
          // try once
          max_tries: 1,
        },
      },
    }

    configureStore(initialState, storeConfig, (err: Error, store: Store) => {
      if (err) {
        logger.error('Error configuring store', err)
      }

      // NOTE it's important to set initialising _after_ the store, so we don't
      // trigger a re-render where initialising is false and store is still
      // undefined (see conditional in render)
      setStore(store)
      setInitialising(false)
    })
  })

  useUnmount(() => {
    console.debug('Unmounting App.tsx')
  })

  if (initialising) return <PageLoader />

  return (
    <Provider store={store}>
      <CacheProvider>
        <Style rules={resetStyles} />
        <LanguageProvider>
          <NiceModal.Provider>
            <BrowserRouter>
              <ScrollToTop />
              <Switch>
                <Route component={LoginCallback} path="/login/callback" />
                <Route component={Login} path="/login" />
                <Route component={Logout} path="/logout" />
                <Route component={ResetPassword} path="/reset-password" />
                <Route component={Signup} path="/signup/invite/:inviteToken" />
                <PrivateRoute component={LegacyApp} path="/" />
              </Switch>
            </BrowserRouter>
          </NiceModal.Provider>
        </LanguageProvider>
      </CacheProvider>
    </Provider>
  )
}

// Scroll to top helper that resets scroll position to the top of the page on
// every transition. Without this, page transitions will retain the scroll
// position of the previous page
// See: https://stackoverflow.com/a/54343182
function ScrollToTop(): JSX.Element {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen((_location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0)
      }
    })

    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
