import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { colors } from 'config/theme'

const styles = {
  base: {
    alignItems: 'center',
    borderColor: colors.gray.lighter,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    outline: 'none',
    overflow: 'hidden',
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 12,
    paddingTop: 6,
  },
  icon: {
    display: 'block',
    fontSize: 21,
    marginRight: 2,
  },
  reference: {
    color: colors.gray.light,
  },
  locationCount: {
    color: colors.gray.light,
    paddingLeft: 5,
  },
}

const iconConfig = {
  checked: {
    name: 'check-checked',
    color: colors.gray.light,
  },
  unchecked: {
    name: 'check-unchecked',
    color: colors.gray.lighter,
  },
  indeterminate: {
    name: 'check-semi',
    color: colors.gray.light,
  },
}

export default SelectDropdownItem

function SelectDropdownItem(props) {
  const {
    index,
    isActive,
    item,
    getItemProps,
    label,
    checkedStatus,
    measure,
    style: virtualizedStyles = {},
    value,
    reference,
  } = props

  // NOTE We need to manually trigger the measure method of the CellMeasurer
  // cache to trigger the height to be rendered correctly. We're not sure why
  // that is as there's not always synchronous data here but it fixes the issue
  useEffect(() => {
    measure()
  }, [value])

  const { height, left, position, top, width } = virtualizedStyles
  const style = {
    ...styles.base,
    backgroundColor: isActive ? colors.blue.lightest : colors.white,
    borderTopWidth: index === 0 ? 0 : styles.base.borderTopWidth,
    height,
    left,
    position,
    top,
    width,
  }

  const icon = iconConfig[checkedStatus]
  const iconStyles = {
    ...styles.icon,
    color: icon && icon.color,
  }

  const hasLocationCount = item.locationCount !== undefined

  const labelStyles = {
    ...styles.label,
    display: hasLocationCount && 'flex',
  }
  const title = reference ? `${label} ${reference}` : label

  // NOTE we're purposely not using our common components here for performance reasons
  return (
    <div
      key={value}
      style={style}
      title={title}
      {...getItemProps({
        index,
        item,
      })}
    >
      {icon && <i className={`icon-${icon.name}`} style={iconStyles} />}
      <div style={labelStyles}>
        <div>{label}</div>
        {hasLocationCount && (
          <div style={styles.locationCount}>{`(${item.locationCount})`}</div>
        )}
        <div style={styles.reference}>{reference}</div>
      </div>
    </div>
  )
}

SelectDropdownItem.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
}
