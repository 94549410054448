import { chunk, concat, defaults, fill, map, slice, take } from 'lodash'
import Promise from 'bluebird'

import {
  DEFAULT_IMAGE_COLUMN_LENGTH,
  DEFAULT_SUMMARY_FIELDS_COLUMN_LENGTH,
} from '../../../constants'
import { buildSummaryField, text } from '../'

const defaultLayout = {
  fillColor: zebraFillColor,
  hLineWidth: () => 0,
  paddingLeft: () => 5,
  paddingRight: () => 5,
  paddingTop: () => 5,
  paddingBottom: () => 5,
  vLineWidth: () => 0,
}

export const GRAY = '#DDDDDD'
export const LIGHT_BLUE = '#F0FBFF'
export const WHITE = '#FFFFFF'

export function imageTables(images) {
  const imageRows = chunk(images, DEFAULT_IMAGE_COLUMN_LENGTH)
  const tables = map(imageRows, (imageRow, index) => {
    const defaultRow = fill(Array(DEFAULT_IMAGE_COLUMN_LENGTH), { text: '' })
    const row = concat(imageRow, slice(defaultRow, imageRow.length))

    const isMiddleRow = index !== 1 && index !== imageRows.length
    const margin = isMiddleRow ? 5 : 0

    return [
      {
        headerRows: 0,
        table: {
          body: [[...row]],
          widths: ['*', '*'],
        },
        layout: {
          hLineColor: () => GRAY,
          hLineWidth: () => 1,
          paddingLeft: () => 5,
          paddingRight: () => 5,
          paddingTop: () => 5,
          paddingBottom: () => 5,
          vLineColor: () => GRAY,
          vLineWidth: () => 1,
        },
        margin: [0, margin, 0, margin],
      },
    ]
  })

  return tables
}

export async function summaryFieldsTable({ fields, settings, timezone }) {
  const tableHeader = map(fields, ({ label }) =>
    text(label, { bold: true, fontSize: 7 })
  )
  const defaultHeaders = fill(Array(DEFAULT_SUMMARY_FIELDS_COLUMN_LENGTH), {
    text: '',
  })
  const defaultRow = fill(Array(DEFAULT_SUMMARY_FIELDS_COLUMN_LENGTH), {
    text: '',
  })
  const tableRow = await Promise.map(fields, field =>
    buildSummaryField({ field, settings, timezone })
  )

  // NOTE: a user can only select three summary fields on the template however
  // if the form group is repeatable we could have more than the default
  // summary field column length so cap otherwise pdf will fail to generate
  const headers = take(
    concat(tableHeader, slice(defaultHeaders, tableHeader.length)),
    DEFAULT_SUMMARY_FIELDS_COLUMN_LENGTH
  )
  const row = take(
    concat(tableRow, slice(defaultRow, tableRow.length)),
    DEFAULT_SUMMARY_FIELDS_COLUMN_LENGTH
  )

  const summaryFieldTable = table({
    body: [headers, row],
    colSpan: 5,
    dontBreakRows: true,
    headerRows: 0,
    layout: {
      hLineWidth: () => 0,
      paddingLeft: () => 0,
      paddingRight: () => 5,
      paddingTop: () => 2,
      paddingBottom: () => 2,
      vLineWidth: () => 0,
    },
    // NOTE: pdfmake applies margin/padding to nested tables
    margin: [0, -5, 0, 0],
    widths: ['*', '*', '*'],
  })

  return summaryFieldTable
}

export function summaryStatTable(options) {
  const tableOptions = defaults({}, options, {
    layout: {
      fillColor: LIGHT_BLUE,
      hLineWidth: () => 0,
      paddingLeft: () => 10,
      paddingRight: () => 10,
      paddingTop: () => 2,
      paddingBottom: () => 2,
      vLineWidth: () => 0,
    },
    widths: ['*'],
  })

  return table(tableOptions)
}

export function summaryWrapperTable(options) {
  const tableOptions = defaults({}, options, {
    layout: {
      fillColor: WHITE,
      hLineWidth: () => 0,
      paddingLeft: () => 0,
      paddingRight: () => 10,
      paddingTop: () => 0,
      paddingBottom: () => 0,
      vLineWidth: () => 0,
    },
    style: 'summaryWrapperTable',
  })

  return table(tableOptions)
}

export function table(options) {
  const {
    body,
    colSpan,
    dontBreakRows = false,
    headerRows = 1,
    layout = defaultLayout,
    style = 'table',
    margin,
    widths,
  } = options

  const definition = {
    colSpan,
    layout,
    style,
    table: {
      body,
      // NOTE: be wary of this feature, if a row spans multiple pages it won't
      // be drawn on to the pdf, only pass true here when row will be less than
      // a page
      dontBreakRows,
      headerRows,
    },
    margin,
  }

  // NOTE: only adds widths if passed
  // as otherwise pdfmake will error!
  // if none defined it auto calculates
  if (widths) {
    definition.table.widths = widths
  }

  return definition
}

export function twoColumnTable(options) {
  const tableOptions = defaults({}, options, {
    widths: ['50%', '50%'],
  })
  return table(tableOptions)
}

export function threeColumnTable(options) {
  const tableOptions = defaults({}, options, {
    widths: ['33%', '33%', '*'],
  })
  return table(tableOptions)
}

export function fourColumnTable(options) {
  const tableOptions = defaults({}, options, {
    widths: ['25%', '25%', '25%', '25%'],
  })

  return table(tableOptions)
}

export function sixColumnTable(options) {
  const tableOptions = defaults({}, options, {
    widths: ['16.7%', '16.66%', '16.66%', '16.66%', '16.66%', '16.66%'],
  })

  return table(tableOptions)
}

export function zebraFillColor(index) {
  return index % 2 === 0 ? WHITE : LIGHT_BLUE
}
