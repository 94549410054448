import { getModule } from '@lighthouse/sdk'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'
import React, { Component } from 'react'
import styles from './styles'

import { get, isEmpty, map } from 'lodash'

import { set, setUserId } from '../../../tracking'

// Components
import { Flex } from 'components/common'
import ErrorBoundary from 'components/error-boundary'
import Notification from 'components/notification'
import Notifications from 'components/notifications'
import Spinner from 'components/spinner'
import { BatchStatusPanel } from 'components/useBatch'
import NavBar from 'modules/nav-bar'
import FlagsHOC from 'components/flags/hoc'
import { BranchProvider } from 'components/BranchProvider'

import emitter from '../../../utils/emitter'

const appModule = getModule('app')
const userApplicationsModule = getModule('userApplications')

class App extends Component {
  constructor() {
    super()
  }

  componentWillMount() {
    const {
      currentApplication,
      logoutHandler,
      user = {},
      setTimezone,
      timezone,
    } = this.props

    if (!currentApplication) return logoutHandler()

    const applicationName = get(currentApplication, 'application.name')
    const userId = get(user, 'data._id')

    // set analytics properties
    set({ dimension1: applicationName })
    setUserId(userId)

    // set guessed timezone if undefined
    if (isEmpty(timezone)) {
      setTimezone(moment.tz.guess())
    }
  }

  componentDidUpdate(prevProps) {
    const { online: prevOnline } = prevProps
    const { online: nextOnline } = this.props

    const wasOnline = prevOnline === true
    const isNowOffline = nextOnline === false
    const isNowOnline = nextOnline === true

    if (isNowOnline) {
      emitter.emit('notification:removeByTag', 'network-error')
    }

    // NOTE only show the connection error if previously
    // was connected as otherwise user could be spammed
    if (wasOnline && isNowOffline) {
      emitter.emit('notification:add', {
        message:
          "We'll attempt to reconnect, but there maybe a problem with your connection",
        theme: 'alert',
        tags: ['network-error'],
        title: 'Disconnected Error',
      })
    }
  }

  render() {
    const {
      loading,
      currentApplication,
      userApplications,
      user,
      logoutHandler,
      switchApplicationHandler,
      fetchApplications,
      children,
    } = this.props

    const applications = map(userApplications.applications, 'application')

    return (
      <div style={styles.root}>
        {/**
         * Use Style component to set global styles in a style tag.
         * This can be used for global resets but should be used
         * minimally to ensure our components and their styles are
         * encapsulated
         */}
        <Notifications emitter={emitter}>
          {props => (
            <Flex
              margin={10}
              position="fixed"
              right={0}
              top={120}
              zIndex={9999}
            >
              <Flex alignSelf="flex-end" flexDirection="column" width={500}>
                {props.notifications.map(
                  ({ id, message, messageT, theme, title }) => (
                    <Notification
                      key={id}
                      onHide={() => props.removeNotification(id)}
                      message={message}
                      messageT={messageT}
                      theme={theme}
                      title={title}
                    />
                  )
                )}
              </Flex>
            </Flex>
          )}
        </Notifications>
        <NavBar
          applications={applications}
          currentApplication={currentApplication}
          logoutHandler={logoutHandler}
          switchApplicationHandler={switchApplicationHandler}
          fetchApplications={fetchApplications}
          user={user}
        />
        {loading ? (
          <div style={{ height: 300 }}>
            <Spinner align="middle" />
          </div>
        ) : (
          <div id="main" style={styles.main}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        )}
        <BranchProvider
          condition={this.props.flags.rolloutBulkUpdateIssues}
          TruthyComponent={BatchStatusPanel}
        />
      </div>
    )
  }
}

export default compose(
  FlagsHOC,
  connect(mapStateToProps, mapDispatchToProps)
)(App)

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    currentApplication: userApplicationsModule.getCurrentApplication(state),
    online: state.offline.online,
    timezone: state.app.timezone,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTimezone: value => dispatch(appModule.setTimezone(value)),
  }
}
