import Promise from 'bluebird'
import { isEmpty, round } from 'lodash'

import {
  buildAuditContent,
  buildAuditFollowUps,
  buildTemplateContent,
  generateDefinition,
  getFormattedAddress,
  horizontalLine,
  text,
  twoColumnTable,
} from '../helpers'

import { getAuditEntryDetails } from '../../helpers'

/**
 * buildAuditPdf
 *
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} pdfOptions.flags - flags to conditionally render parts of the pdf
 * @param {object} data - pdf data
 * @param {object} data.entity - audit document
 * @param {object} data.locations - locations documents
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {string} data.timezone - timezone string
 * @param {object} data.users - application user documents
 * @returns {Promise} returns pdfmake definition object
 */
export function buildAuditPdf(pdfOptions, data) {
  const { entity, timezone } = data
  const { flags = {} } = pdfOptions

  console.info('buildAuditPdf')

  const sequenceId = entity.sequenceId
  const timestamp = entity.createdAt
  const title = entity.title || 'Unknown'
  const fileTitle = `Audit Report - ${title}`

  return generateContent(data, flags)
    .then(content =>
      generateDefinition({
        content,
        fileTitle,
        sequenceId,
        timestamp,
        timezone,
        type: 'Audit',
        ...pdfOptions,
      })
    )
    .catch(err => {
      throw new Error(`BuildAuditPdfError: ${err.message}`)
    })
}

function generateContent(data, flags) {
  const { rolloutAuditFollowUps } = flags
  const { entity } = data

  const {
    followUps = [],
    footerFields = {},
    gps = {},
    headerFields = {},
    score = {},
    timezone,
    title = 'Unknown',
  } = entity

  const entityDetails = getAuditEntryDetails(data)

  const {
    gpsText,
    groupedData,
    locationText,
    referenceValue,
    timezoneHourTime,
    scoreText,
    target,
    targetServiceLevel,
  } = entityDetails

  const renderTargetFields = !!targetServiceLevel
  const headerTitle = text(title, { style: 'title' })
  const headerScore = text(scoreText, { alignment: 'right', style: 'title' })
  const firstRow = [headerTitle, headerScore]
  const subTitle = `${locationText ||
    gpsText} - ${timezoneHourTime} by ${referenceValue}`

  let headerSubTitle = text(subTitle, { colSpan: 2, style: 'subTitle' })
  let secondRow = [headerSubTitle]

  const reverseGeocoded = gps.reverseGeocoded
  const address = !isEmpty(reverseGeocoded)
    ? getFormattedAddress(reverseGeocoded)
    : ''
  const renderHeaderAddress = !isEmpty(reverseGeocoded)
  const dummyColumn = text(' ', { style: 'small' })

  let headerAddress = text(address, { colSpan: 2, style: 'small' })
  const renderThirdRow = renderHeaderAddress || renderTargetFields
  let thirdRow = renderHeaderAddress ? [headerAddress] : []

  if (renderTargetFields) {
    headerSubTitle = text(subTitle, { style: 'subTitle' })

    const serviceLevelBelow = targetServiceLevel === 'below'
    const serviceLevelText =
      targetServiceLevel === 'above'
        ? 'Above Target'
        : targetServiceLevel === 'on'
        ? 'On Target'
        : targetServiceLevel === 'below'
        ? 'Below Target'
        : ''

    const scoreServiceLevelSubTitle = text(serviceLevelText, {
      alignment: 'right',
      style: serviceLevelBelow ? 'serviceLevelBelow' : 'serviceLevelAboveOrOn',
    })

    secondRow = [headerSubTitle, scoreServiceLevelSubTitle]
    headerAddress = text(address, { style: 'small' })

    const truncatedTargetPercent = round(target, 2)
    const scoreTargetText = `(Target - ${truncatedTargetPercent}%)`
    const scoreTargetSubTitle = text(scoreTargetText, {
      alignment: 'right',
      style: 'subTitle',
    })

    thirdRow = renderHeaderAddress
      ? [headerAddress, scoreTargetSubTitle]
      : [dummyColumn, scoreTargetSubTitle]
  }

  const scoreBreakdown = text(
    `${round(score.actual, 2)} / ${round(score.max, 2)}`,
    {
      alignment: 'right',
      style: 'totalScore',
    }
  )
  const scorePercentage = text(scoreText, {
    alignment: 'right',
    colSpan: 2,
    style: 'totalAuditScore',
  })
  const scoreTitle = text('Total Score', { style: 'totalScore' })

  const body = renderThirdRow
    ? [firstRow, secondRow, thirdRow]
    : [firstRow, secondRow]

  const titleTable = twoColumnTable({
    body,
    layout: 'noBorders',
    style: 'titleTable',
    widths: ['*', 100],
    margin: [0, 0, 0, 30],
  })

  const totalScoreTable = twoColumnTable({
    body: [[scoreTitle, scoreBreakdown], [scorePercentage]],
    layout: 'noBorders',
    widths: ['*', 100],
  })

  const hLineTop = horizontalLine({ margin: [0, 10, 0, 0] })
  const hLineBottom = horizontalLine()

  const followUpItems = rolloutAuditFollowUps
    ? buildAuditFollowUps(followUps, { timezone })
    : []

  const promises = {
    entry: buildAuditContent(groupedData.items, { rolloutAuditFollowUps }),
    footerTemplate: buildTemplateContent(footerFields.formGroups, data),
    headerTemplate: buildTemplateContent(headerFields.formGroups, data),
  }

  const auditItemsTitle = rolloutAuditFollowUps
    ? [
        {
          text: 'Audit Items',
          style: {
            font: 'Gotham',
            lineHeight: 1.1,
          },
        },
        hLineTop,
      ]
    : []

  return Promise.props(promises)
    .then(({ entry, footerTemplate, headerTemplate }) => [
      titleTable,
      followUpItems,
      ...auditItemsTitle,
      ...headerTemplate,
      ...entry,
      hLineTop,
      totalScoreTable,
      hLineBottom,
      ...footerTemplate,
    ])
    .catch(err => {
      throw new Error(`GenerateContentError: ${err.message}`)
    })
}
