import PropTypes from 'prop-types'
import React from 'react'

import Background from 'components/background'
import { Anchor, Block, Flex, Span } from 'components/common'
import Logo from 'components/logo'

import { colors } from 'config/theme'
import { useTranslation } from 'react-i18next'

export default AuthWrapper

function AuthWrapper({ children }) {
  const [t, i18n] = useTranslation()

  return (
    <Background>
      <Flex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        flexShrink={0}
        justifyContent="center"
      >
        <Logo />
        {children}
      </Flex>
      <Flex
        color={colors.white}
        flexDirection="column"
        fontSize={14}
        fontWeight={300}
      >
        <Block marginBottom={20}>
          <Span>
            {t('login.footerMessage')}{' '}
            <Anchor
              color={colors.white}
              cursor="pointer"
              href="https://lighthouse.io/#contact"
              target="_blank"
              textDecoration="underline"
            >
              {t('login.requestAccount')}
            </Anchor>
          </Span>
        </Block>
        <Flex justifyContent="center" marginBottom={30}>
          <Anchor
            color={colors.white}
            cursor="pointer"
            href="https://lighthouse.io/terms"
            padding="0 10px 10px 0"
            target="_blank"
            textDecoration="underline"
          >
            {t('terms')}
          </Anchor>
          <Anchor
            color={colors.white}
            cursor="pointer"
            href="https://lighthouse.io/privacy"
            padding="0 10px 10px 0"
            target="_blank"
            textDecoration="underline"
          >
            {t('privacy')}
          </Anchor>
        </Flex>
      </Flex>
    </Background>
  )
}

AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
