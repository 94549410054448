import React from 'react'

import TemplateEntry from 'components/templates/entry'
import QuestionWrapper from './question/question-wrapper'
import { useFlags } from 'components/flags/hook'
import { FollowUpsList } from './FollowUpsList'

export default function AuditTemplateEntry(props) {
  const {
    form,
    formValues,
    groupScores,
    isEditing,
    readOnly,
    templateEntity,
    id,
  } = props

  const showErrorOnUnTouched = isEditing && id

  const flags = useFlags()
  const { followUps = {} } = templateEntity

  return (
    <>
      <TemplateEntry
        dataTestIdPrefix="header"
        form={form}
        formValues={formValues.headerFields}
        headerFields={templateEntity.headerForm}
        isEditing={isEditing}
        readOnly={readOnly}
        small
        showErrorOnUnTouched={showErrorOnUnTouched}
        templateEntity={templateEntity.headerForm}
        name="headerFields.formGroups"
      />
      {!isEditing && flags.rolloutAuditFollowUps && followUps.length > 0 && (
        <FollowUpsList followUps={followUps} />
      )}

      <QuestionWrapper
        form={form}
        isEditing={isEditing}
        readOnly={readOnly}
        small
        templateEntity={templateEntity}
        items={templateEntity.items}
        id={id}
        formValues={formValues}
        groupScores={groupScores}
      />
      <TemplateEntry
        dataTestIdPrefix="footer"
        form={form}
        formValues={formValues.footerFields}
        footerFields={templateEntity.headerForm}
        isEditing={isEditing}
        readOnly={readOnly}
        showErrorOnUnTouched={showErrorOnUnTouched}
        small
        templateEntity={templateEntity.footerForm}
        name="footerFields.formGroups"
      />
    </>
  )
}
