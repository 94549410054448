import { includes } from 'lodash/fp'
import moment from 'moment-timezone'

import { Period, Unit } from '../scheduling.types'

interface GenerateScheduleEnd {
  readonly frequency: Period
  readonly start: number
  readonly timezone: string
}

/**
 * Generates a schedule end datetime based on the start and frequency
 */
export function generateScheduleEnd({
  frequency,
  start,
  timezone,
}: GenerateScheduleEnd): number {
  const { unit: frequencyUnit, value: frequencyValue } = frequency

  const isFrequencyLessThanWeek = includes(frequencyUnit, [
    Unit.Millisecond,
    Unit.Second,
    Unit.Minute,
    Unit.Hour,
    Unit.Day,
  ])

  const mStart = moment.tz(start, timezone)

  // NOTE: if frequency unit less than a week we must set end to jump a week
  // plus the interval length of the schedule frequency
  // so that we can ensure that we are within a service interval
  const end = isFrequencyLessThanWeek
    ? mStart.add(2, Unit.Week).valueOf()
    : mStart.add(frequencyValue * 2, frequencyUnit).valueOf()

  return end
}
