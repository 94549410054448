import Radium from 'radium'
import React from 'react'

export default Radium(Base)

function Base(props) {
  const {
    children,
    className,
    dataAnalytics,
    dataTestId,
    fontFamily = '"system-ui", "Helvetica Neue", Helvetica, Arial, sans-serif',
    hoverBackgroundColor,
    onClick,
    onKeyDown,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseOut,
    onMouseOver,
    reference,
    title,
    ...style
  } = props

  // TODO I know, this is incredibly gnarly, but it's just to get us started
  // with a pattern for passing these props. Long term we should check for any
  // psuedo class via its prefix. Alternatively we could just swap out our
  // custom components for jsx-style which support this pattern
  if (hoverBackgroundColor) {
    style[':hover'] = {
      backgroundColor: hoverBackgroundColor,
    }
  }

  return (
    <div
      className={className}
      data-analytics={dataAnalytics}
      data-testid={dataTestId}
      fontFamily={fontFamily}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={reference}
      style={style}
      title={title}
    >
      {children}
    </div>
  )
}
