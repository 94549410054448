import React from 'react'
import Radium from 'radium'
import { noop } from 'lodash'
import { Block } from 'components/common'
import styles from './styles'

export default Radium(MaterialCheckbox)

function MaterialCheckbox(props) {
  const {
    customStyles,
    children,
    checked = false,
    disabled,
    indeterminate,
    input = {},
    name,
    onChange,
    overrideChecked,
    readOnly,
    required,
    value,
    dataAnalytics,
    dataTestId,
  } = props

  const checkboxValue = overrideChecked || input.value || checked || !!value
  const handleChange = input.onChange || onChange
  const canEdit = !disabled && !readOnly
  const status = indeterminate
    ? 'indeterminate'
    : checkboxValue
    ? 'checked'
    : 'unchecked'
  const permission = canEdit ? 'enabled' : 'disabled'
  const base = styles.base[status]
  const overrides = styles[permission][status]

  if (readOnly) {
    return <Block padding={10}>{status === 'checked' ? 'Yes' : 'No'}</Block>
  }

  return (
    <div style={[styles.root, customStyles]} onClick={e => e.stopPropagation()}>
      <label>
        <span style={[base.span.before, overrides.span.before]} />
        <span style={base.span.after} />
        <input
          checked={checkboxValue}
          data-analytics={dataAnalytics}
          data-testid={dataTestId}
          disabled={disabled}
          name={name}
          onChange={canEdit ? handleChange : noop}
          required={required}
          style={[base.checkbox, overrides.checkbox]}
          type="checkbox"
          value={checkboxValue}
        />
      </label>
      {children}
    </div>
  )
}
