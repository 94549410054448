import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import PageLoader from 'components/page-loader'
import { usePlugin } from 'components/usePlugin'

import { ProtectedRoute } from '../../../../components/protectedRoute'

const BulkAdd = Loadable({
  loader: () => import('./components/bulk-add'),
  loading: PageLoader,
})

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})
const DeletedList = Loadable({
  loader: () => import('./components/deleted-list'),
  loading: PageLoader,
})

export default function UsersRoute() {
  const { enabled: cognitoEnabled } = usePlugin('cognito')

  return (
    <Switch>
      <Route component={BulkAdd} exact path="/settings/users/bulk-add" />
      <Route component={Entry} exact path="/settings/users/entry/:id" />
      <ProtectedRoute
        component={Entry}
        exact
        path="/settings/users/entry"
        permissions={() => !cognitoEnabled}
      />
      <ProtectedRoute
        component={DeletedList}
        path="/settings/users/deleted"
        permissions={{ action: 'delete', module: 'applicationUser' }}
      />
      <Route component={List} path="/settings/users" />
      <Redirect to="/404" />
    </Switch>
  )
}
