import { useContext, useEffect, useState } from 'react'
import { Block, Flex, Text } from 'components/common'
import { ChevronDown, ChevronLeft } from 'lucide-react'
import React from 'react'
import { Batch, BatchState } from './types'
import { BatchQueueContext, getBatchStatus } from './useBatchQueue'

export const BatchStatusPanel: React.FC = () => {
  const { queue, actorRef, getBatchStatus, getQueueStatus } = useContext(
    BatchQueueContext
  )

  const [isExpanded, setExpanded] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const queueComplete =
    getQueueStatus(queue).status === BatchState.COMPLETE

  const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
    if (!queueComplete) {
      event.preventDefault()
      setExpanded(true)
    }
  }

  useEffect(() => {
    setExpanded(!!queue.length)
    setVisible(!!queue.length)

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue])

  function hide() {
    if (queueComplete) {
      actorRef.send({ type: 'QUEUE.CLEAN' })
      setVisible(false)
    }
  }

  return (
    (isVisible && (
      <Block
        backgroundColor="white"
        borderRadius="12px 12px 0 0"
        boxShadow="0 -4px 20px rgba(0, 0, 0, 0.15)"
        inset="auto 80px 0 auto"
        maxHeight="40vh"
        margin="auto"
        overflow="hidden"
        position="fixed"
        width={480}
        zIndex="999999"
        border="1px solid rgba(0, 170, 228, 0.2)"
      >
        <Flex
          backgroundColor="#50aae41a"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          padding="1em 1.25em"
          cursor="pointer"
          onClick={() => setExpanded(!isExpanded)}
          position="relative"
          borderBottom={
            isExpanded ? '1px solid rgba(0, 170, 228, 0.1)' : 'none'
          }
        >
          <Flex alignItems="center" justifyContent="center">
            <Text
              color="#00aae4"
              fontSize={18}
              fontWeight="400"
              margin={0}
              letterSpacing="-0.3px"
            >
              Batch Updates
            </Text>
            <Text
              background="rgba(0, 170, 228, 0.15)"
              borderRadius="50%"
              color="#00aae4"
              display="inline-block"
              margin="0 0.5em"
              width={22}
              height={22}
              lineHeight="22px"
              fontSize={12}
              textAlign="center"
            >
              {queue.length}
            </Text>
          </Flex>

          <Flex alignItems="center">
            {queueComplete ? (
              <Block
                padding="0.4em 0.9em"
                marginRight="0.75em"
                background="rgba(0, 170, 228, 0.08)"
                borderRadius="4px"
                onClick={hide}
              >
                <Text color="#00aae4" fontSize={14} margin={0}>
                  Close
                </Text>
              </Block>
            ) : null}
            <Flex
              onClick={e => {
                e.stopPropagation()
                setExpanded(!isExpanded)
              }}
            >
              {isExpanded ? (
                <ChevronDown color="#00aae4" />
              ) : (
                <ChevronLeft color="#00aae4" />
              )}
            </Flex>
          </Flex>
        </Flex>
        {isExpanded ? (
          <Block
            padding="1em 1.25em 1.25em"
            backgroundColor="white"
            position="relative"
            maxHeight="30vh"
            overflow="auto"
            scrollbarWidth="thin"
            scrollbarColor="rgba(0, 170, 228, 0.3) transparent"
          >
            {queue.length ? (
              queue.map((batch, index) => (
                <BatchItem
                  key={index}
                  batch={batch}
                  getBatchStatus={getBatchStatus}
                  isLast={index === queue.length - 1}
                />
              ))
            ) : (
              <Flex
                justifyContent="center"
                padding="1.5em 0"
                backgroundColor="rgba(0, 170, 228, 0.03)"
                borderRadius="6px"
              >
                <Text color="#999" fontSize={15}>
                  No batch updates in progress
                </Text>
              </Flex>
            )}
          </Block>
        ) : null}
      </Block>
    )) ||
    null
  )
}

interface BatchItemProps {
  batch: Batch
  getBatchStatus: getBatchStatus
  isLast: boolean
}

const BatchItem: React.FC<BatchItemProps> = ({
  batch,
  getBatchStatus,
  isLast,
}) => {
  const { failedCount, queuedCount, completeCount } = getBatchStatus(batch)

  const hasCompleted = batch.state === BatchState.COMPLETE
  const derivedState =
    failedCount > 0
      ? BatchState.FAILED
      : hasCompleted
      ? BatchState.COMPLETE
      : BatchState.QUEUED

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      paddingBottom={isLast ? '0' : '1.25em'}
      marginBottom={isLast ? '0' : '1.25em'}
      borderBottom={isLast ? 'none' : '1px solid rgba(0, 170, 228, 0.08)'}
    >
      <Block paddingRight=".75em">
        <DotStatus status={derivedState} />
      </Block>
      <Block width="100%">
        <Flex flexWrap="wrap" gap="0.75em" alignItems="center">
          <BatchItemText>{batch.label || 'Batch Update'}</BatchItemText>
          <StatusCounter
            label="In Progress"
            count={queuedCount}
            color="#00aae4"
            backgroundColor="rgba(0, 170, 228, 0.1)"
          />
          <StatusCounter
            label="Failed"
            count={failedCount}
            color="#f44336"
            backgroundColor="rgba(244, 67, 54, 0.1)"
          />
          <StatusCounter
            label="Complete"
            count={completeCount}
            color="#56ad56"
            backgroundColor="rgba(86, 173, 86, 0.1)"
          />
        </Flex>
      </Block>
    </Flex>
  )
}

interface StatusCounterProps {
  label: string
  count: number
  color: string
  backgroundColor: string
}

const StatusCounter: React.FC<StatusCounterProps> = ({
  label,
  count,
  color,
  backgroundColor,
}) => (
  <Flex
    alignItems="center"
    padding="0.25em 0.75em"
    backgroundColor={backgroundColor}
    borderRadius="4px"
    opacity={count ? 1 : 0.5}
  >
    <Text color={color} fontSize={13} fontWeight="500" margin={0}>
      {label}: {count}
    </Text>
  </Flex>
)

const BatchItemText: React.FC = props => (
  <Text inline {...props}>
    {props.children}
  </Text>
)

interface DotStatusProps {
  status: BatchState
}

const DotStatus: React.FC<DotStatusProps> = props => {
  const { status } = props
  const defaultColor =
    status === BatchState.QUEUED
      ? '#00aae4'
      : status === BatchState.COMPLETE
      ? '#56ad56'
      : '#f44336'
  const [color, setColor] = useState(defaultColor)

  const shouldBlink = status === 'QUEUED'

  useEffect(() => {
    if (shouldBlink) {
      const interval = setInterval(() => {
        setColor(color === defaultColor ? 'transparent' : defaultColor)
      }, 500)

      return () => clearInterval(interval)
    } else {
      setColor(defaultColor)
    }
  }, [color, defaultColor, shouldBlink])

  return (
    <Block
      width={8}
      height={8}
      backgroundColor={color}
      borderRadius="50%"
      boxShadow={shouldBlink ? `0 0 0 4px ${color}1a` : 'none'}
      transition="box-shadow 0.3s ease"
    />
  )
}
