import { useSelector } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { get } from 'lodash'

interface DayforceOptions {
  secretId: string
}

interface WinteamOptions {
  teamtime: { enabled: boolean }
  schedule: { enabled: boolean }
}

interface CognitoOptions {
  userPoolId: string
  requiredAttributes?: {
    email: boolean
    mobile: boolean
  }
}

interface TemplaOptions {
  ftpCollections: Array<'auditentries' | 'taskentries'>
}

interface Plugin {
  options?: CognitoOptions | DayforceOptions | TemplaOptions | WinteamOptions
  enabled: boolean
  secrets?: { awsCustomerAccountId: string }
}

type PluginType =
  | 'cognito'
  | 'dataLake'
  | 'dayforce'
  | 'loops'
  | 'templa'
  | 'timegate'
  | 'winteam'

const userApplicationsModule = getModule('userApplications')

export function usePlugin(type: PluginType): Plugin {
  const currentApplication = useSelector(state =>
    userApplicationsModule.getCurrentApplication(state)
  )
  const plugins = currentApplication.application.plugins
  const enabled = get(plugins[type], 'enabled', false)
  const options = get(plugins[type], 'options', {})
  const secrets = get(plugins[type], 'secrets', {})

  return { enabled, options, secrets }
}
