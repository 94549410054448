import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import styles from './styles'

export default Radium(FieldSet)

function FieldSet(props) {
  const { columns, disabled } = props
  const columnWrapper = `${props.columns}Wrapper`
  const children = React.Children.map(props.children, child => (
    <div style={[styles[columns]]}>{child}</div>
  ))

  return (
    <fieldset
      style={[styles.root, styles[columnWrapper], disabled && styles.disabled]}
      disabled={disabled}
    >
      {children}
    </fieldset>
  )
}

FieldSet.propTypes = {
  columns: PropTypes.string,
}
