import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '4em',
    paddingTop: 30,
    paddingBottom: 10,
  },

  noMargin: {
    paddingTop: 0,
  },

  border: {
    borderBottom: `1px solid ${colors.gray.lighter}`,
  },

  title: {
    fontSize: 26,
    fontWeight: '100',
    color: colors.gray.normal,
    margin: 0,
  },

  link: {
    color: colors.gray.normal,
    transition: '0.2s',
    ':hover': {
      color: colors.gray.darker,
    },
  },

  help: {
    color: colors.gray.light,
    display: 'block',
    fontSize: '28px',
  },

  options: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}
