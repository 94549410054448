{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-28",
    "endpointPrefix": "runtime.lex",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Lex Runtime Service",
    "serviceId": "Lex Runtime Service",
    "signatureVersion": "v4",
    "signingName": "lex",
    "uid": "runtime.lex-2016-11-28"
  },
  "operations": {
    "PostContent": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "contentType",
          "inputStream"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "S5",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "requestAttributes": {
            "shape": "S5",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-request-attributes"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          },
          "inputStream": {
            "shape": "S8"
          }
        },
        "payload": "inputStream"
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "intentName": {
            "location": "header",
            "locationName": "x-amz-lex-intent-name"
          },
          "slots": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-slots"
          },
          "sessionAttributes": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "message": {
            "shape": "Sc",
            "location": "header",
            "locationName": "x-amz-lex-message"
          },
          "messageFormat": {
            "location": "header",
            "locationName": "x-amz-lex-message-format"
          },
          "dialogState": {
            "location": "header",
            "locationName": "x-amz-lex-dialog-state"
          },
          "slotToElicit": {
            "location": "header",
            "locationName": "x-amz-lex-slot-to-elicit"
          },
          "inputTranscript": {
            "location": "header",
            "locationName": "x-amz-lex-input-transcript"
          },
          "audioStream": {
            "shape": "S8"
          }
        },
        "payload": "audioStream"
      },
      "authtype": "v4-unsigned-body"
    },
    "PostText": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/text"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "inputText"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sg"
          },
          "requestAttributes": {
            "shape": "Sg"
          },
          "inputText": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentName": {},
          "slots": {
            "shape": "Sg"
          },
          "sessionAttributes": {
            "shape": "Sg"
          },
          "message": {
            "shape": "Sc"
          },
          "messageFormat": {},
          "dialogState": {},
          "slotToElicit": {},
          "responseCard": {
            "type": "structure",
            "members": {
              "version": {},
              "contentType": {},
              "genericAttachments": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "title": {},
                    "subTitle": {},
                    "attachmentLinkUrl": {},
                    "imageUrl": {},
                    "buttons": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "text",
                          "value"
                        ],
                        "members": {
                          "text": {},
                          "value": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "string",
      "sensitive": true
    },
    "S8": {
      "type": "blob",
      "streaming": true
    },
    "Sc": {
      "type": "string",
      "sensitive": true
    },
    "Sg": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    }
  }
}