{
  "name": "@lighthouse/sdk",
  "version": "14.53.0",
  "description": "Lighthouse.io SDK for JavaScript applications",
  "main": "lighthouse-sdk.js",
  "module": "lib/index.js",
  "scripts": {
    "build": "yarn clear && yarn build:main && yarn build:module ",
    "build:main": "yarn clear:main && node_modules/.bin/webpack --progress",
    "build:module": "yarn clear:module && node_modules/.bin/babel src --out-dir lib/ --ignore '**/test*' --source-maps",
    "clear": "yarn clear:main && yarn clear:module",
    "clear:main": "node_modules/.bin/rimraf lighthouse-sdk.js && node_modules/.bin/rimraf lighthouse-sdk.js.map",
    "clear:module": "node_modules/.bin/rimraf lib",
    "dev": "yarn clear && yarn build:main --watch && yarn build:module --watch",
    "lint": "node_modules/.bin/eslint src",
    "prepublishOnly": "yarn build",
    "preversion": "yarn test",
    "release:auto": "auto shipit --verbose",
    "test": "BABEL_ENV=test node_modules/.bin/mocha --reporter dot",
    "test:coverage": "BABEL_ENV=test ./node_modules/.bin/nyc ./node_modules/.bin/mocha",
    "test:ci": "yarn test:coverage -- --reporter mocha-junit-reporter",
    "version": "yarn build"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/Lighthouse-io/sdk-js.git"
  },
  "author": "Will McClellan",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/Lighthouse-io/sdk-js/issues"
  },
  "homepage": "https://github.com/Lighthouse-io/sdk-js#readme",
  "devDependencies": {
    "auto": "^7.0.12",
    "babel-cli": "^6.24.1",
    "babel-core": "^6.24.1",
    "babel-eslint": "^7.2.3",
    "babel-loader": "^7.0.0",
    "babel-plugin-syntax-object-rest-spread": "^6.13.0",
    "babel-plugin-transform-object-assign": "^6.22.0",
    "babel-plugin-transform-object-rest-spread": "^6.23.0",
    "babel-preset-es2015": "^6.24.1",
    "eslint": "^6.0.1",
    "eslint-config-airbnb-base": "^11.2.0",
    "eslint-plugin-import": "^2.2.0",
    "eslint-plugin-jsx-a11y": "^5.0.1",
    "mocha": "^3.4.1",
    "mocha-junit-reporter": "^1.13.0",
    "nock": "^9.0.13",
    "nyc": "^10.1.2",
    "rimraf": "^2.5.4",
    "should": "^11.2.1",
    "sinon": "^1.17.7",
    "webpack": "^2.5.1",
    "webpack-bundle-analyzer": "^2.8.1"
  },
  "dependencies": {
    "@lighthouse/hall-pass": "^3.0.1",
    "@lighthouse/redux-offline": "^1.2.0",
    "@lighthouse/redux-undo": "^1.1.1",
    "@turf/turf": "^5.1.6",
    "bluebird": "^3.5.0",
    "bluebird-retry": "^0.11.0",
    "cuid": "^1.3.8",
    "es6-symbol": "^3.1.0",
    "fetch-ponyfill": "^4.0.0",
    "lodash": "^4.0.0",
    "moment": "^2.19.3",
    "moment-timezone": "^0.5.0",
    "parse-link-header": "^1.0.0",
    "pluralize": "^3.1.0",
    "pubnub": "^3.16.5",
    "query-string": "^4.3.1",
    "raven-js": "^3.10.0",
    "redux": "^3.6.0",
    "redux-batched-actions": "^0.2.0",
    "redux-hook-middleware": "^0.1.1",
    "redux-logger": "^3.0.1",
    "redux-persist": "^4.3.0",
    "redux-recycle": "^1.2.0",
    "redux-thunk": "^2.2.0",
    "reselect": "^2.5.4",
    "seamless-immutable": "^7.0.1",
    "supercluster": "^7.1.5",
    "validator": "^7.0.0"
  },
  "volta": {
    "node": "8.11.3",
    "yarn": "1.16.0"
  }
}
