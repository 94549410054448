import assign from 'lodash/assign'
import find from 'lodash/fp/find'
import includes from 'lodash/fp/includes'
import intersection from 'lodash/fp/intersection'
import keys from 'lodash/fp/keys'
import omit from 'lodash/fp/omit'
import qs from 'query-string'
import transform from 'lodash/transform'
import urljoin from 'url-join'

const FORWARD_PATH = '/search.php'
const REVERSE_PATH = '/reverse.php'
const VALID_OPTIONS = [
  'accept-language',
  'addressdetails',
  'bounded',
  'countrycodes',
  'dedupe',
  'extratags',
  'format',
  'json_callback',
  'lat',
  'limit',
  'lon',
  'namedetails',
  'polygon',
  'polygon_geojson',
  'polygon_kml',
  'polygon_svg',
  'polygon_text',
  'viewbox',
  'zoom',
]
const REQUIRED_OPTS = ['apiKey', 'host', 'text', 'lat', 'lon']
const COMMON_OPTS = {
  format: 'json',
}
const OPTION_MAP = {
  text: 'q',
}

const optionValidator = buildOptionValidator(VALID_OPTIONS)

export default function geocoder(options) {
  if (!options.host) {
    throw new Error('`host` option not specified')
  }

  if (!options.fetch) {
    throw new Error('`fetch` ponyfill option not specified')
  }

  if (!options.apiKey) {
    throw new Error('`apiKey` option not specified')
  }

  return {
    forward: forward(options),
    reverse: reverse(options),
  }
}

function forward({ host, fetch, apiKey }) {
  return options => {
    if (!options.text) {
      return Promise.reject(
        new Error('`text` option not specified for search')
      )
    }

    const restOptions = omit(REQUIRED_OPTS)(options)
    const invalidOption = findInvalidOption(restOptions)

    if (invalidOption) {
      return Promise.reject(
        new Error(`Invalid option '${invalidOption}' supplied to search`)
      )
    }

    const reqOptions = assign(
      {},
      COMMON_OPTS,
      transformOptions(options),
      {
        key: apiKey,
      },
    )
    const url = buildUrl(host, FORWARD_PATH, reqOptions)

    return fetch(url)
      .then(checkStatus)
      .then(response => response.json())
  }
}

function reverse({ host, fetch, apiKey }) {
  return options => {
    if (!options.lat || !options.lon) {
      return Promise.reject(
        new Error('`lat` and `lon` values not specified for reverse')
      )
    }

    const restOptions = omit(REQUIRED_OPTS)(options)
    const invalidOption = findInvalidOption(restOptions)

    if (invalidOption) {
      return Promise.reject(
        new Error(`Invalid option '${invalidOption}' supplied to reverse`)
      )
    }

    const reqOptions = assign(
      {},
      COMMON_OPTS,
      transformOptions(options),
      {
        key: apiKey,
      },
    )
    const url = buildUrl(host, REVERSE_PATH, reqOptions)
    return fetch(url)
      .then(checkStatus)
      .then(response => response.json())
  }
}

function buildUrl(host, path, query) {
  const querystring = qs.stringify(query)
  const parsedUrl = urljoin(host, path)
  return `${parsedUrl}?${querystring}`
}

// https://github.com/developit/unfetch
function checkStatus(response) {
  if (response.ok) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response
    return Promise.reject(error)
  }
}

function findInvalidOption(options) {
  const optionKeys = keys(options)
  const invalidOption = find(optionValidator)(optionKeys)
  return invalidOption
}

function buildOptionValidator(validOptions) {
  return option => {
    const validOption = includes(option)(validOptions)
    return !validOption
  }
}

// Transforms `options` keys to those mapped in OPTION_MAP
function transformOptions(options = {}) {
  return transform(options, (accum, value, key) => {
    const keyMatch = OPTION_MAP[key]
    const newKey = keyMatch || key
    accum[newKey] = value
  })
} 
