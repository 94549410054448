import Radium from 'radium'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { WithPermissions } from '@lighthouse/react-components'
import styles from './styles'
import { chain, get, isArray, map } from 'lodash'
import { getPathParts } from 'utils/location-helper'

// Components
import { Flex } from 'components/common'
import navigationBuilder from 'components/navigation'
import Link from 'components/link'
import Icon from 'components/icon'
import NavList from 'modules/nav-list'
import TimezoneBar from 'modules/timezone-bar'
import { withTranslation } from 'react-i18next'

export default compose(
  WithPermissions,
  withRouter,
  navigationBuilder(),
  withHandlers({
    getSettingsLink,
  }),
  withTranslation(),
  Radium
)(NavBar)

function NavBar(props) {
  const {
    applications,
    currentApplication,
    fetchApplications,
    getSettingsLink,
    logoutHandler,
    navigation,
    switchApplicationHandler,
    user = {},
    t,
  } = props

  const isAdmin = user.email === 'support@lighthouse.io'
  // TODO - pass in location as prop instead of looking on
  // window.location
  const pathParts = getPathParts(window.location.pathname)
  const section = pathParts[0]
  const userFullName = user && `${user.firstName} ${user.lastName}`

  // NOTE In some scenarios, currentApplication can be unset, in which case we
  // shouldn't render anything until it does. The user should really be seeing
  // the navbar if they don't have an application
  if (!currentApplication) return null

  const { application } = currentApplication

  const settingsLink = getSettingsLink()

  const otherNavigation = {
    user: {
      order: 1,
      label: userFullName,
      caret: true,
      dropdown: {
        align: 'full',
        options: [
          {
            dataTestId: 'settings-button',
            label: t('navigation.settings'),
            link: settingsLink,
          },
          {
            dataTestId: 'admin-button',
            label: 'Admin',
            labelT: 'navigation.admin',
            link: '/admin/applications',
            disabled: !isAdmin,
          },
          {
            dataTestId: 'logout-button',
            label: t('navigation.logout'),
            onClick: logoutHandler,
          },
        ],
      },
    },
  }

  const navItems = {}

  map(navigation, (nav, key) => {
    navItems[key] = {
      ...nav,
      label: nav.labelT
        ? isArray(nav.labelT)
          ? t(...nav.labelT)
          : t(nav.labelT)
        : nav.label,
    }
  })

  // If user has access to multiple applications then add the
  // application switcher to menu bar
  if (applications.length > 1 && !otherNavigation.appSwitcher) {
    const label = currentApplication ? application.name : 'Switch Application'

    const applicationOptions = chain(applications)
      .map(buildApplicationItem(switchApplicationHandler))
      .sortBy('label')
      .value()

    otherNavigation.appSwitcher = {
      order: 0,
      label,
      caret: true,
      dropdown: {
        align: 'full',
        // fetch applications when clicking the switch app dropdown
        onClick: fetchApplications,
        options: applicationOptions,
      },
    }
  }

  const customLogo = get(application, 'theme.logos.console')
  const logo = customLogo ? (
    <div style={[styles.logoCustom]}>
      <img
        alt=""
        crossOrigin="anonymous"
        src={customLogo}
        style={[styles.logoImg]}
        title={application.name}
      />
    </div>
  ) : (
    <div style={[styles.logoCustom]}>
      <img
        alt="lighthouse logo"
        crossOrigin="anonymous"
        src="https://assets-lighthouse-io.s3.amazonaws.com/img/lighthouse-color-logo-2023.png"
        style={[styles.logoImg]}
        title={application.name}
      />
    </div>
  )

  return (
    <header style={[styles.root]}>
      <Link to="/" style={[styles.logo]} logo>
        {logo}
      </Link>
      <div style={[styles.nav]}>
        <NavList type="primary" items={navItems} active={section} />
      </div>
      <div style={[styles.nav, styles.navRight]}>
        <NavList
          borders="left"
          type="primary"
          items={otherNavigation}
          active={section}
        />
      </div>
      <div style={styles.nav}>
        <TimezoneBar />
      </div>
      <div style={styles.nav}>
        <Flex>
          <div style={styles.pendoNavItem} id="pendo_resources">
            <Icon theme={styles.logoHelp} name="info" hoverText="Resources" />
          </div>
        </Flex>
      </div>
    </header>
  )
}

function buildApplicationItem(onClickCallback) {
  return ({ _id, name }) => ({
    label: name,
    onClick: () => onClickCallback(_id),
  })
}

function getSettingsLink({ hasModulePermission }) {
  return () => {
    if (hasModulePermission('applicationUser', 'read')) {
      return '/settings/users'
    }

    if (hasModulePermission('role', 'read')) {
      return '/settings/roles'
    }

    return '/settings/profile'
  }
}
