import { assign, log, setup } from 'xstate'
import { createBatchMachine } from './batchMachine'
import { BatchState } from './types'

import { ActorRefFrom } from 'xstate'
import { Batch, BatchRequest } from './types'
import { BatchActorRef } from './batchMachine'

export interface QueueContext {
  queue: Batch[]
  currentBatchActor: BatchActorRef | null
}

export type QueueEvents =
  | { type: 'ENQUEUE'; batch: BatchRequest }
  | { type: 'PROCESS_NEXT' }
  | { type: 'BATCH.COMPLETE'; batch: Batch }
  | { type: 'BATCH.FAILED' }
  | { type: 'QUEUE.CLEAN' }

export type QueueMachine = ReturnType<typeof createQueueMachine>
export type QueueActorRef = ActorRefFrom<QueueMachine>

export const createQueueMachine = () =>
  setup({
    types: {
      context: {} as QueueContext,
      events: {} as QueueEvents,
    },
    actions: {
      enqueue: assign({
        queue: ({ context, event }) => {
          if (event.type !== 'ENQUEUE') return context.queue

          return [
            {
              id: event.batch.id,
              label: event.batch.label,
              items: event.batch.payloads.map((payload, index) => ({
                id: `${event.batch.id}-item-${index}`,
                payload,
                state: BatchState.QUEUED,
              })),
              state: BatchState.QUEUED,
              processingFn: event.batch.processingFn,
            },
            ...context.queue,
          ]
        },
      }),
    },
  }).createMachine({
    id: 'queueManager',
    initial: 'idle',
    context: {
      queue: [],
      currentBatchActor: null,
    },
    states: {
      idle: {
        on: {
          ENQUEUE: {
            actions: [
              'enqueue',
              log(({ event }) => `Enqueued batch ${event.batch.id}`),
            ],
            target: 'ready',
          },
        },
      },
      ready: {
        always: [
          {
            target: 'processing',
            guard: ({ context }) => {
              return (
                context.queue.some(
                  batch => batch.state === BatchState.QUEUED
                ) && !context.currentBatchActor
              )
            },
          },
        ],
        on: {
          ENQUEUE: {
            actions: [
              'enqueue',
              log(({ event }) => `Enqueued batch ${event.batch.id}`),
            ],
          },
          'QUEUE.CLEAN': {
            target: 'ready',
            actions: [
              assign(({ context }) => {
                return {
                  queue: context.queue.filter(
                    (batch: Batch) => batch.state !== BatchState.COMPLETE
                  ),
                }
              }),
            ],
          },
        },
      },
      processing: {
        entry: [
          assign(({ context, spawn }) => {
            const batchMachine = createBatchMachine()
            const batchActor = spawn(batchMachine)
            const nextBatch = context.queue[0]
            batchActor.send({ type: 'PROCESS', batch: nextBatch })

            return {
              queue: context.queue,
              currentBatchActor: batchActor,
            }
          }),
          log(
            ({ context }) => `Processing batch ${context.currentBatchActor?.id}`
          ),
        ],
        on: {
          ENQUEUE: {
            actions: [
              'enqueue',
              log(({ event }) => `Enqueued batch ${event.batch.id}`),
            ],
          },
          'BATCH.COMPLETE': {
            target: 'ready',
            actions: [
              log(({ event }) => 'Batch Complete ' + event.batch.id),
              assign(({ context, event }) => {
                const nextQueue = context.queue.map(batch =>
                  batch.id === event.batch.id ? event.batch : batch
                )
                return {
                  currentBatchActor: null,
                  queue: nextQueue,
                }
              }),
            ],
          },
          'BATCH.FAILED': {
            target: 'ready',
            actions: [
              assign(() => {
                return { currentBatchActor: null }
              }),
            ],
          },
          'QUEUE.CLEAN': {
            target: 'ready',
            actions: [
              assign(({ context }) => {
                return {
                  queue: context.queue.filter(
                    (batch: Batch) => batch.state !== BatchState.COMPLETE
                  ),
                }
              }),
            ],
          },
        },
      },
    },
  })
