import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useActorRef, useSelector } from '@xstate/react'
import { createQueueMachine, QueueActorRef } from './queueMachine'
import { Batch, BatchRequest, BatchState } from './types'

type BatchQueueContextValue = {
  actorRef: QueueActorRef | null
  enqueueBatch: (batchRequest: BatchRequest) => void
  getQueueStatus: getQueueStatus
  getBatchStatus: getBatchStatus
  queue: Batch[]
}

export type getBatchStatus = (
  batch: Batch
) => { failedCount: number; queuedCount: number; completeCount: number }

const getBatchStatus: getBatchStatus = (batch: Batch) => {
  return batch.items.reduce(
    (acc, item) => {
      switch (item.state) {
        case BatchState.QUEUED:
          acc.queuedCount += 1
          break
        case BatchState.FAILED:
          acc.failedCount += 1
          break
        case BatchState.COMPLETE:
          acc.completeCount += 1
          break
      }
      return acc
    },
    { failedCount: 0, queuedCount: 0, completeCount: 0 }
  )
}

type getQueueStatus = (
  queue: Batch[]
) => {
  status: string
}

const getQueueStatus: getQueueStatus = queue => {
  const allComplete = queue.every(batch =>
    batch.items.every(
      item =>
        item.state == BatchState.COMPLETE || item.state == BatchState.FAILED
    )
  )

  return allComplete
    ? { status: BatchState.COMPLETE }
    : { status: BatchState.IN_PROGRESS }
}
export const BatchQueueContext = createContext<BatchQueueContextValue>(null)

export function BatchQueueProvider({ children }) {
  const queueMachine = useMemo(() => createQueueMachine(), [])
  const actorRef = useActorRef(queueMachine)

  const { queue } = useSelector(actorRef, state => state.context)

  const currentBatchActor = useSelector(
    actorRef,
    state => state.context.currentBatchActor
  )
  const currentBatch = useSelector(
    currentBatchActor,
    state => state?.context?.batch
  )

  const enqueueBatch = useCallback(
    (batchRequest: BatchRequest) => {
      actorRef.send({ type: 'ENQUEUE', batch: batchRequest })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actorRef]
  )
  const currentBatchId = currentBatch?.id ?? ''

  const nextQueue = useMemo(() => {
    if (!currentBatch || queue.length === 0) {
      return queue
    }

    return [currentBatch, ...queue.slice(1)]
  }, [queue, currentBatch])

  useEffect(() => {
    const processItems = async () => {
      for (const item of currentBatch.items) {
        if (item.state !== BatchState.QUEUED) continue
        try {
          await currentBatch.processingFn(item.payload)
          currentBatchActor.send({ type: 'ITEM.COMPLETE', itemId: item.id })
        } catch (error) {
          currentBatchActor.send({
            type: 'ITEM.FAIL',
            itemId: item.id,
            error: error as Error,
          })
        }
      }
    }

    if (currentBatch) {
      processItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBatchId])

  return (
    <BatchQueueContext.Provider
      value={{
        actorRef,
        enqueueBatch,
        getQueueStatus,
        getBatchStatus,
        queue: nextQueue,
      }}
    >
      {children}
    </BatchQueueContext.Provider>
  )
}

export function useBatchQueue() {
  const context = useContext(BatchQueueContext)
  return {
    ...context,
  }
}
