class ExtendableError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
    this.message = message || 'Error'
    Error.captureStackTrace(this, this.constructor.name)
  }
}

export class InvalidParameterError extends ExtendableError {
  /**
   * @classdesc This error is returned in the callback function of `ask` when there is something wrong with the input parameters.
   * @constructor InvalidParameterError
   * @param  {String} message Error message, "Invalid parameter error" by default.
   * @return None
   * @see module:ask~ask
   */
  constructor(message) {
    super(message || 'Invalid parameter error')
    this.name = 'InvalidParameterError'
  }
}

export class ForbiddenError extends ExtendableError {
  /**
   * @classdesc This error is returned in the callback function of `ask` when permission check failed.
   * @constructor ForbiddenError
   * @param  {String} message Error message, "Forbidden" by default.
   * @return None
   * @see module:ask~ask
   */
  constructor(message) {
    super(message || 'Forbidden')
    this.name = 'ForbiddenError'
  }
}
