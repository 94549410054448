import { chain, find, get, omit } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import { connect } from 'react-redux'
import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import Button from 'components/button'
import { Block, Flex } from 'components/common'
import Icon from 'components/icon'
import Select from '../select'
import withUrlQuery from 'components/with-url-query'

const areaModule = getModule('areas')

export default compose(
  withRouter,
  withUrlQuery,
  connect(mapStateToProps)
)(LocationSelect)

function LocationSelect(props) {
  const {
    className,
    clearable,
    dataTestId = 'location-field',
    disabled,
    disableSelectedList,
    error,
    history,
    locations,
    mapTargetMode,
    multi,
    onBlur,
    onChange,
    placeholder,
    required,
    resource,
    showRemoveItems,
    showSelectAll,
    selectAllLimit,
    urlQuery,
    value,
    type,
    widthText,
  } = props

  const isObjectValue = value && value.length && typeof value[0] === 'object'

  const classes = error ? `${className}, error` : className

  function getReference(location) {
    const sin = get(location, 'entity.plugins.timegate.options.SiteSIN', null)
    const jobNumber = get(
      location,
      'entity.plugins.winteam.options.jobNumber',
      null
    )
    const ref = get(location, 'entity.reference', null)

    const reference = sin
      ? `SIN: ${sin}`
      : jobNumber
      ? `Job #${jobNumber}`
      : ref
      ? `Ref: #${ref}`
      : null

    return reference
  }

  const options = useMemo(
    () =>
      chain(locations)
        .map(location => ({
          label: location.entity.name,
          lat: location.entity.center && location.entity.center.coordinates[1],
          lng: location.entity.center && location.entity.center.coordinates[0],
          search: location.entity.search,
          value: location.entity._id,
          reference: getReference(location),
        }))
        .sortBy('label')
        .value(),
    [locations]
  )

  return mapTargetMode ? (
    <Flex alignItems="center" justifyContent="space-between">
      <Block width="100%" marginRight="20px">
        <Select
          className={classes}
          clearable={clearable}
          dataTestId={dataTestId}
          disabled={disabled}
          disableSelectedList={disableSelectedList}
          onBlur={onBlur}
          onChange={handleSelectChange}
          options={options}
          placeholder={placeholder}
          required={required}
          resource={resource}
          reverseLocation
          value={isObjectValue ? value.map(val => val.id) : value}
          widthText="72%"
          showRemoveItems={showRemoveItems}
          showSelectAll={showSelectAll}
          selectAllLimit={selectAllLimit}
          type={type}
        />
      </Block>
      <Button
        height={40}
        marginRight={0}
        onClick={handleTargetClick}
        width={40}
      >
        <Icon fontSize={20} fontWeight={400} lineHeight={1} name="map-target" />
      </Button>
    </Flex>
  ) : (
    <Block position={'relative'}>
      <Select
        className={classes}
        clearable={clearable}
        dataTestId={dataTestId}
        disabled={disabled}
        disableSelectedList={disableSelectedList}
        multi={multi}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        required={required}
        value={isObjectValue ? value.map(val => val.id) : value}
        showRemoveItems={showRemoveItems}
        showSelectAll={showSelectAll}
        selectAllLimit={selectAllLimit}
        type={type}
        widthText={widthText}
      />
    </Block>
  )

  function handleTargetClick() {
    const nextUrlQuery = omit(urlQuery, ['lat', 'lng'])

    const nextSearch = queryString.stringify({
      ...nextUrlQuery,
      showMarker: true,
    })

    history.push({ search: `?${nextSearch}` })
  }

  function handleSelectChange(value) {
    const option = find(options, ['value', value])

    // TODO: we could possibly move this out into the form so this has
    // separation of concerns, feels odd doing this here
    const nextSearch = queryString.stringify({
      ...urlQuery,
      lat: option.lat,
      lng: option.lng,
      showMarker: true,
    })

    history.push({ search: `?${nextSearch}` })

    // NOTE: We must send the value back as a label/value object
    // we define this in the form state so must be consistent
    onChange({ label: option.label, value: option.value })
  }
}

function mapStateToProps(state, props) {
  const areaSelectors = areaModule.selectors(state)()
  // TODO remove locations cache when loop times setup page is removed
  const { options = {} } = props

  const locations = options.forceLegacy
    ? state.locations.cache
    : areaSelectors.byType('location')

  return { locations }
}
