import React, { useRef } from 'react'
import { getModule } from '@lighthouse/sdk'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import * as logger from 'utils/logger'
import { useRequest, withRequest, Request } from './useRequest'

const applicationUserModule = getModule('applicationUsers')

// Global pending requests tracking object
const pendingRequests: Record<string, Promise<User | undefined>> = {}

// NOTE the user is in fact an application user in terms of model used from the
// API
interface User {
  firstName: string
  lastName: string
  username: string
}

interface CachedUser {
  entity: User
  id: string
}

interface Response {
  json: User
  lastKey: string | null
  links: string | null
  totalCount: number | null
}

interface FetchUserOptions {
  userId: string
}

type FetchUser = (options: FetchUserOptions) => Promise<User | undefined>

interface WrappedFetchUserOptions extends FetchUserOptions {
  getUserSelector: (userId: string) => CachedUser | undefined
  addToCache: (data: User) => void
  request: Request
  pendingRequests?: Record<string, Promise<User | undefined>>
}

type WrappedFetchUser = (
  options: WrappedFetchUserOptions
) => Promise<User | undefined>

interface UseUsersResult {
  fetchUser: FetchUser
}

export function useUsers(): UseUsersResult {
  const { request } = useRequest()
  const dispatch = useDispatch()
  const applicationUserSelectors = useSelector(state =>
    applicationUserModule.selectors(state)()
  )

  // Component-level tracking of in-flight requests
  const componentPendingRequestsRef = useRef<
    Record<string, Promise<User | undefined>>
  >({})

  const fetchUser: FetchUser = async ({ userId }) => {
    return await wrappedFetchUser({
      addToCache: data => dispatch(applicationUserModule.addToCache(data)),
      getUserSelector: applicationUserSelectors.getApplicationUser,
      request,
      userId,
      pendingRequests: componentPendingRequestsRef.current, // Pass component-level tracking
    })
  }

  return {
    fetchUser,
  }
}

export const withUsers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRequest,
  withHandlers({
    addToCache: props => user => props.addToCache(user),
    fetchUser: (props): FetchUser => async ({ userId }) => {
      return await wrappedFetchUser({
        addToCache: data => props.addToCache(data),
        getUserSelector: props.getApplicationUser,
        request: props.request,
        userId,
        pendingRequests, // Use the global tracking for HOC
      })
    },
  })
)

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)()

  return {
    getApplicationUser: applicationUserSelectors.getApplicationUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addToCache: (user: User) =>
      dispatch(applicationUserModule.addToCache(user)),
  }
}

const wrappedFetchUser: WrappedFetchUser = async ({
  addToCache,
  getUserSelector,
  request,
  userId,
  pendingRequests = globalPendingRequests, // Default to global if not provided
}) => {
  try {
    console.debug('FetchUser', { userId })

    // Check cache first
    const cachedUser = getUserSelector(userId)
    if (cachedUser?.entity) {
      console.debug('FetchUser returning cached user entity', {
        cachedUser: cachedUser.entity,
      })
      return cachedUser.entity
    }

    // Check for in-flight requests for this user
    if (pendingRequests[userId]) {
      console.debug('FetchUser returning existing request', { userId })
      return pendingRequests[userId]
    }

    // Create and track new request promise
    const requestPromise = (async () => {
      try {
        const response = await request<User>(
          `/users/actions/get-by-user/${userId}`
        )

        const user = response.json

        console.debug('FetchUser success!', { user })

        addToCache(user)
        return user
      } catch (err) {
        console.error('FetchUserError', {
          err,
        })
        logger.error('FetchUserError', {
          err: err.message,
          stack: err.stack,
        })
        return undefined
      } finally {
        // Clean up the pending request
        delete pendingRequests[userId]
      }
    })()

    // Store promise in tracking object
    pendingRequests[userId] = requestPromise

    return requestPromise
  } catch (err) {
    console.error('FetchUserError', {
      err,
    })
    logger.error('FetchUserError', {
      err: err.message,
      stack: err.stack,
    })
    return undefined
  }
}

// Use named global variable for clarity
const globalPendingRequests = pendingRequests
