import moment from 'moment-timezone'
import { getStatusDetails, getUserFullName } from '../../../helpers'
import { defaultStyles, horizontalLine, sixColumnTable } from '../'
import { launchIcon, internalLinkIcon } from '../../../images'

const LIGHTHOUSE_BASE_URL = 'https://app.lighthouse.io'

export function buildAuditFollowUps(rawFollowUpIssues = [], options = {}) {
  const sectionTitle = [
    {
      text: 'Follow-up Issues',
      style: {
        font: 'Gotham',
        lineHeight: 1.1,
        margin: [0, 0, 0, 50],
      },
    },
  ]
  const sectionDivider = horizontalLine({ margin: [0, 0, 0, 0] })

  const followUpIssues = rawFollowUpIssues.filter(item => !item.errorMessage)
  if (!followUpIssues.length) {
    // Show a `no follow-up issues` message if there are no follow up issues
    return [
      sectionTitle,
      sectionDivider,
      {
        text: 'No follow-up issues exist for this audit',
        style: defaultStyles.subTitle,
        margin: [0, 5, 0, 50],
      },
    ]
  }

  const { timezone = 'America/New_York' } = options
  const tableBody = []
  followUpIssues.forEach(item => {
    const mappedStatus = getStatusDetails(item.status)
    const status = {
      text: mappedStatus.text.toUpperCase(),
      style: {
        ...defaultStyles.small,
        color: mappedStatus.style.color,
        alignment: 'center',
      },
    }

    let updatedAt = ''
    if (item.updatedAt) {
      const format = 'MMM D h:mma' // e.g. Jan 21 12:59am in the Audit timezone
      updatedAt = moment.tz(item.updatedAt, timezone).format(format)
    }

    const link = `${LIGHTHOUSE_BASE_URL}/reports/issues/${item.issueId}`
    const iconLinkCell = launchIcon
      ? { alignment: 'center', fit: [8, 8], image: launchIcon, link }
      : { text: '' }

    const linkToDestination = item.questionId
    const iconJumpCell = launchIcon
      ? {
          alignment: 'center',
          fit: [8, 8],
          image: internalLinkIcon,
          linkToDestination,
        }
      : { text: '' }

    const assignees = item.assignees || []
    const assigneeUsername =
      assignees.length === 0
        ? 'Unassigned'
        : assignees.length === 1
        ? // TODO Currently this gives `first last`, I don't think it can fall back to 'Unknown User' here
          getUserFullName(assignees, 0)
        : `${assignees.length} users`

    tableBody.push([
      iconLinkCell,
      { text: item.issueTitle || '', link, style: 'small', image: launchIcon },
      status,
      { text: assigneeUsername, style: 'small' },
      iconJumpCell,
      { text: item.questionLabel || '', style: 'small', linkToDestination },
      { text: updatedAt || '', style: 'small', alignment: 'right' },
    ])
  })

  const headerLinkIconCell = launchIcon
    ? { alignment: 'center', fit: [8, 8], image: launchIcon }
    : { text: '' }

  const headerJumpIconCell = launchIcon
    ? { alignment: 'center', fit: [8, 8], image: internalLinkIcon }
    : { text: '' }

  const headerStyle = {
    bold: true,
    font: 'Gotham',
    fontSize: 8,
    alignment: 'left',
  }

  const headerRow = [
    headerLinkIconCell,
    { text: 'Issue Title', style: headerStyle },
    { text: 'Status', style: headerStyle, alignment: 'center' },
    { text: 'Assignees', style: headerStyle },
    headerJumpIconCell,
    { text: 'Audit Question', style: headerStyle },
    { text: 'Last Updated', style: headerStyle, alignment: 'right' },
  ]

  const followUpsTable = sixColumnTable({
    body: [headerRow, ...tableBody],
    style: 'titleTable',
    widths: [6, '*', 55, 55, 6, '*', '16%'],
    margin: [0, 0, 0, 30],
  })

  return [sectionTitle, sectionDivider, followUpsTable]
}
