import React from 'react'
import styles from './styles'

export default function Icon({ name, hoverText, theme, ...styleProps }) {
  const iconName = `icon-${name}`

  // NOTE: defined colourStyle so color can be
  // overridden with styleProps if necessary
  const colourStyle = styles[styleProps.color]

  const iconStyles = {
    ...styles.root,
    ...theme,
    ...styleProps,
    ...colourStyle,
  }

  return <i className={iconName} title={hoverText} style={iconStyles} />
}
