/**
 * BranchProvider
 * Use the BranchProvider when you need to conditionally render a Provider
 * component. The main use case here is you're adding new functionality which
 * has a `Provider` context component at the top of the app. But, you only want
 * that to be used for users with a flag (or other setting) enabled. We can't
 * use basic conditionals within these components because that's against the
 * rules of hooks, so this is a reusable workaround.
 *
 * Props:
 * @prop condition - truthy condition (boolean or predicate function)
 * @prop TruthyComponent - Component to render when condition is truthy
 * @prop FalsyComponent (default: DummyProvider) - Component to render when condition is false
 */

import React from 'react'

interface BranchProviderProps {
  children: React.ReactNode
  condition: boolean | (() => boolean)
  TruthyComponent: React.FunctionComponent
  FalsyComponent?: React.FunctionComponent
}

export function BranchProvider(props: BranchProviderProps): JSX.Element {
  const {
    children,
    condition,
    TruthyComponent,
    FalsyComponent = DummyProvider,
  } = props
  if (condition) {
    return <TruthyComponent>{children}</TruthyComponent>
  }

  return <FalsyComponent>{children}</FalsyComponent>
}

interface DummyProviderProps {
  children: React.ReactNode
}

export function DummyProvider(props: DummyProviderProps): JSX.Element {
  return <>{props.children}</>
}
