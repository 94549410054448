import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux'

import {
  createPersistor,
  getStoredState,
} from 'redux-persist'

import { offline } from '@lighthouse/redux-offline'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import batch from '@lighthouse/redux-offline/lib/defaults/batch'
import defaultDetectNetwork from '@lighthouse/redux-offline/lib/defaults/detectNetwork'

import { compact, get, isEmpty, noop, reduce, size } from 'lodash'

import hookMiddleware from 'redux-hook-middleware'
import createTrackingMiddleware from '../modules/tracking/middleware'
import createRequestMiddleware from '../middleware/request'
import createLogsMiddleware from '../modules/logs/middleware'
import moduleReducers from '../modules'
import messagesMiddleware from '../middleware/messages'
import { majorVersionChange } from '../modules/version/helpers'
import { setVersion } from '../modules/version'
import request from '../request'
import { version } from '../constants'

// Detect browser environment to safely access `window` for redux-devtools
// https://stackoverflow.com/questions/17575790/environment-detection-node-js-or-browser/31090240#31090240
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}')
const reduxLoggerStub = {
  info: () => {},
  error: () => {},
  warn: () => {},
}

// NOTE retry backoff schedule for redux-offline.
// It's important not to go OTT with this setting (as in the module defaults)
// because it can cause requests to be delayed for a long time. E.g. a delay of
// 5 mins would hold up the latest request and all other requests behind it.
// This causes issues at logout because it forces the user to wait for delay to
// expire before a retry. Instead we favour a more aggressive fail-fast
// schedule, which fails the request earlier but allows the user to retry
const offlineDecaySchedule = [
  1000,     // 1 seconds
  1000 * 3, // 3 seconds
  1000 * 8, // 8 seconds
]

const defaultPersistenceOpts = {
  debounce: 50,
}

// NOTE
// export the store object so we can access it outside our react components.
// This needs to be manually assigned once createStore is called
export let store

export default function configureStore(initialState = {}, config, callback = noop) {
  const {
    clickTrackingFn,
    baseUrl,
    logger,
    offlineLogger,
    storageAdapter,
    reduxLogger = reduxLoggerStub,
    reduxLoggerPredicate = () => false,
    reducers = {},
    requestOptions = {},
    detectNetwork,
  } = config

  // combine module reducers with any other custom or dynamically
  // created reducers
  const reducersObj = Object.assign(moduleReducers, reducers)
  const rootReducer = combineReducers(reducersObj)

  const persistenceOpts = Object.assign(
    {},
    defaultPersistenceOpts,
    {
      storage: storageAdapter,
    },
  )

  const offlineConfig = {
    batch,
    detectNetwork: detectNetwork || defaultDetectNetwork,
    discard: offlineDiscardFn,
    effect: offlineEffectFn(requestOptions),
    logger: offlineLogger || console,
    // NOTE we have our own custom implementation of redux-persist so we don't
    // need the redux-offline library to handle any of this for us
    persist: noop,
    persistOptions: persistenceOpts,
    retry: offlineRetryFn,
  }

  // middleware
  const requestMiddleware = createRequestMiddleware(baseUrl, requestOptions)

  const trackingMiddleware = createTrackingMiddleware({
    clickTrackingFn,
  })

  const actionLoggerMiddleware = createLogger({
    reduxLogger,
    // NOTE logging substantially reduces performance and should be
    // disabled for production/release builds
    predicate: reduxLoggerPredicate,
    collapsed: true,
    timestamp: false,
  })

  const logsMiddleware = createLogsMiddleware({ logger })

  // Redux DevTools support
  const composeEnhancers = (isBrowser() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const middleware = composeEnhancers(
    applyMiddleware(
      thunk,
      requestMiddleware,
      trackingMiddleware,
      hookMiddleware,
      messagesMiddleware,
      // loggerMiddleware processes our custom logger queue,
      logsMiddleware,
      // actionLoggerMiddleware logs out all redux actions
      actionLoggerMiddleware,
    ),
  )

  // rehydrate the store

  getStoredState(persistenceOpts, (err, restoredState) => {
    if (err) return callback(err)
    const hasRestoredState = !isEmpty(restoredState)

    let state = hasRestoredState
      ? restoredState
      : initialState

    try {
      const hasMajorVersionChange = majorVersionChange(state)

      // reset state if major version has changed. This guards against store data
      // structure change that might otherwise cause JS errors
      if (hasMajorVersionChange) {
        reduxLogger.warn('Major version change detected, resetting state...')
        state = initialState
      }

      if (state.offline) {
        state.offline = sanitizeOfflineState(state.offline)
      }

      const logQueue = get(state, 'logs.queue', {})

      if (size(logQueue) > 0) {
        // NOTE reset all logs back to idle in case they were in a processing
        // status while the app was closed
        state.logs.queue = reduce(logQueue, (acc, log, id) => {
          const resetLog = {
            ...log,
            status: 'idle',
          }
          return {
            ...acc,
            [id]: resetLog,
          }
        }, {})
      }

      // NOTE we need to wrap the store in the offline enhancer to ensure it's
      // actions are collected by other middlewares
      // https://github.com/jevakallio/redux-offline/issues/77
      store = offline(offlineConfig)(createStore)(rootReducer, state, middleware)

      // dispatch version update
      store.dispatch(setVersion(version))

      createPersistor(store, persistenceOpts)

      return callback(null, store)
    } catch (error) {
      return callback(error)
    }
  })
}

// NOTE It's important that fetchHelpers is passed to the offline effect because
// we need it to hook into custom authorization. Without it, when the offline
// queue picks up requests to sync, it wouldn't have access to the function to
// getAuthorization. See the request module for how it's used.
function offlineEffectFn(fetchHelpers) {
  return async (effect, action) => {
    const fetchOpts = {
      method: effect.method,
      headers: effect.headers,
      // avoid timeout
      optimistic: true,
    }

    if (action.payload) {
      // our reducer expects a `data` property
      // requestObj.data = body
      fetchOpts.body = JSON.stringify(action.payload)
    }

    return request(effect.url, fetchOpts, fetchHelpers)
  }
}

function offlineDiscardFn(error = {}) {
  if (!error.status) return true

  // discard http 4xx errors
  return error.status >= 400 && error.status < 500
}

function offlineRetryFn(action, retries) {
  return offlineDecaySchedule[retries] || null
}

// TODO Migrate ot latest version of redux-offline which will have fixes for
// most of these issues
function sanitizeOfflineState(offlineState = {}) {
  const { outbox = [] } = offlineState
  return {
    ...offlineState,
    // NOTE Always reset the busy value of offline to false. This mitigates the
    // state being persisted with a value of true and never picking up offline
    // outbox again
    busy: false,
    retryToken: 0,
    retryCount: 0,
    retryScheduled: false,
    // NOTE Remove any null values from the rehydrated array of actions in the
    // outbox. redux-offline doesn't currently sanitize null actions from this
    // array, so in future we could move this change to the middleware for that
    // module
    outbox: compact(outbox),
  }
}
