import { sendParent, assign, log, setup } from 'xstate'
import { BatchState } from './types'

import { ActorRefFrom } from 'xstate'
import { Batch, BatchItem } from './types'

interface BatchContext {
  batch: Batch | null
  currentItem: BatchItem | null
  error?: Error
}

export type BatchEvents =
  | { type: 'PROCESS'; batch: Batch }
  | { type: 'ITEM.IN_PROGRESS'; itemId: string }
  | { type: 'ITEM.COMPLETE'; itemId: string }
  | { type: 'ITEM.FAIL'; itemId: string; error: Error }
  | { type: 'RESET' }

export type BatchMachine = ReturnType<typeof createBatchMachine>
export type BatchActorRef = ActorRefFrom<BatchMachine>

export const createBatchMachine = () =>
  setup({
    types: {} as {
      context: BatchContext
      events: BatchEvents
    },
  }).createMachine({
    id: 'batchProcessor',
    initial: 'idle',
    context: {
      batch: null,
      currentItem: null,
      error: undefined,
    },
    states: {
      idle: {
        on: {
          PROCESS: {
            target: 'processing',
            actions: [
              assign({
                batch: ({ event }) => ({
                  ...event.batch,
                  state: BatchState.IN_PROGRESS,
                }),
              }),
              log(({ context }) => `Processing batch ${context.batch?.id}`),
            ],
          },
        },
      },
      processing: {
        on: {
          'ITEM.COMPLETE': {
            actions: [
              assign({
                batch: ({ context, event }) => {
                  if (!context.batch) return null
                  return {
                    ...context.batch,
                    items: context.batch.items.map(item =>
                      item.id === event.itemId
                        ? { ...item, state: BatchState.COMPLETE }
                        : item
                    ),
                  }
                },
              }),
              log(
                ({ context, event }) =>
                  `Batch ${context.batch?.id} item ${event.itemId} COMPLETE`
              ),
            ],
            target: 'checkCompletion',
          },
          'ITEM.FAIL': {
            actions: [
              assign({
                batch: ({ context, event }) => {
                  if (!context.batch) return null
                  return {
                    ...context.batch,
                    items: context.batch.items.map(item =>
                      item.id === event.itemId
                        ? {
                            ...item,
                            state: BatchState.FAILED,
                            error: event.error,
                          }
                        : item
                    ),
                  }
                },
              }),
              log(
                ({ context, event }) =>
                  `Batch ${context.batch?.id} item ${event.itemId} FAILED`
              ),
            ],
            target: 'checkCompletion',
          },
        },
      },
      checkCompletion: {
        always: [
          {
            target: 'completed',
            guard: ({ context }): boolean => {
              if (!context.batch) return false

              const isComplete = context.batch.items.every(
                item => item.state === 'COMPLETE' || item.state === 'FAILED'
              )
              return isComplete
            },
          },
          { target: 'processing' },
        ],
      },
      completed: {
        entry: [
          assign({
            batch: ({ context }) =>
              context.batch
                ? { ...context.batch, state: BatchState.COMPLETE }
                : null,
          }),
          log(({ context }) => `Batch ${context.batch?.id} COMPLETE!`),
          sendParent(({ context }) => ({
            type: 'BATCH.COMPLETE',
            batch: context.batch,
          })),
          log(() => `Sent BATCH.COMPLETE to queueManager`),
        ],
        on: {
          RESET: {
            target: 'idle',
            actions: assign({
              batch: null,
              currentItem: null,
              error: undefined,
            }),
          },
        },
      },
    },
  })
