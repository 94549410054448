import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import { validation } from '@lighthouse/sdk'
import moment from 'moment'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { cloneDeep, each, first, get, isArray, join, map, some } from 'lodash'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import { RelationshipField } from 'components/fields'
import { Upload } from 'components/buttons'
import Button from 'components/button'
import emitter from 'utils/emitter'
import {
  FieldSet,
  File,
  GroupHeader,
  Image,
  InputCheckbox,
  InputDate,
  InputDisplayImage,
  InputGroup,
  InputLabel,
  InputNumber,
  InputSelect,
  InputText,
  InputTime,
  InputWysiwyg,
  Signature,
  UsersSelect,
} from 'components/form'

import { TOOLTIPS } from 'config/constants'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const applicationUserModule = getModule('applicationUsers')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined
const isNumber = validation.isNumber()

const DOCUMENT_CONTENT_TYPES = [
  '.doc',
  '.docx',
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-msi',
]

const DOCUMENT_ACCEPT = join(DOCUMENT_CONTENT_TYPES, ',')
const DOCUMENT_TOOLTIP = [TOOLTIPS.document.titleT, TOOLTIPS.document.messageT]
const MEDIA_CONTENT_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'video/mp4',
]
const MEDIA_ACCEPT = join(MEDIA_CONTENT_TYPES, ',')
const MEDIA_MAX_FILES = 30
const MEDIA_TOOLTIP = [TOOLTIPS.media.titleT, TOOLTIPS.media.messageT]

class TemplateEntry extends Component {
  constructor(props) {
    super(props)

    this.createNewField = this.createNewField.bind(this)
    this.renderFormGroupHeader = this.renderFormGroupHeader.bind(this)
    this.renderFormGroups = this.renderFormGroups.bind(this)
    this.renderFieldGroups = this.renderFieldGroups.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.renderField = this.renderField.bind(this)
    this.renderFieldFiles = this.renderFieldFiles.bind(this)
    this.renderFieldImages = this.renderFieldImages.bind(this)
  }

  render() {
    const {
      formValues,
      name,
      readOnly,
      showErrorOnUnTouched,
      showWarnOnUnTouched,
      small,
    } = this.props

    const formGroupName = name ? name : 'formGroups'

    return (
      <Block dataTestId="template-form">
        <FieldArray
          formValues={formValues}
          name={formGroupName}
          component={this.renderFormGroups}
          readOnly={readOnly}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
        />
      </Block>
    )
  }

  renderFormGroups({
    fields,
    formValues,
    readOnly,
    showErrorOnUnTouched,
    showWarnOnUnTouched,
    small,
  }) {
    const { isEditing } = this.props

    const formFormGroups = fields.map((formPath, index) => {
      const {
        canSkip,
        description,
        fieldGroups,
        label,
        options = {},
        repeatable,
        skipped,
      } = fields.get(index)
      const showFormGroup = hasVisibleFieldGroups(fieldGroups)
      const formGroupSkipped =
        get(formValues, `formGroups[${index}].skipped`) || false

      // NOTE: if no fields groups/fields are visible then do not display form
      // group at all
      if (!showFormGroup && !isEditing) return null

      return (
        <Block key={index} dataTestId="form-groups" marginBottom="30px">
          <FieldArray
            canSkip={canSkip}
            component={this.renderFieldGroups}
            description={description}
            formGroupIndex={index}
            formGroupName={formPath}
            formGroupSkipped={formGroupSkipped}
            isEditing={isEditing}
            label={label}
            name={`${formPath}.fieldGroups`}
            readOnly={options?.readOnly || readOnly}
            repeatable={repeatable}
            showErrorOnUnTouched={showErrorOnUnTouched}
            showWarnOnUnTouched={showWarnOnUnTouched}
            skipped={skipped}
            small={small}
          />
        </Block>
      )
    })

    return <div>{formFormGroups}</div>
  }

  renderFormGroupHeader({ label }) {
    return (
      <Flex
        alignItems="center"
        backgroundColor={colors.gray.white}
        borderBottom={`1px solid ${colors.gray.lightest}`}
        borderTop={`1px solid ${colors.gray.lightest}`}
        fontSize={'12px'}
        marginLeft={-10}
        marginRight={-10}
        paddingBottom={10}
        paddingLeft={10}
        paddingRight={10}
        paddingTop={10}
      >
        <Block flexGrow={1}>{label}</Block>
      </Flex>
    )
  }

  renderFieldGroups({
    canSkip,
    description,
    fields,
    formGroupIndex,
    formGroupName,
    formGroupSkipped,
    isEditing,
    label,
    readOnly,
    repeatable,
    showErrorOnUnTouched,
    showWarnOnUnTouched,
    skipped = false,
    small,
  }) {
    const { t } = this.props
    const fieldGroupsLength = fields.length
    const isRepeatable = repeatable === 0 || fieldGroupsLength < repeatable
    const showDefaultFormGroupHeader = !readOnly && fieldGroupsLength === 0

    const defaultFormGroupHeader = this.renderFormGroupHeader({
      label,
    })

    const formFieldGroups = fields.map((fieldGroupPath, index) => {
      const fieldGroupNumber = index + 1
      const isLastField = fieldGroupsLength === fieldGroupNumber
      const showAddAnotherButton = !readOnly && isLastField && isRepeatable
      const showDeleteButton = !readOnly && fieldGroupsLength > 1
      const showDescription = fieldGroupNumber === 1
      const handleDelete = showDeleteButton ? () => fields.remove(index) : null
      const showSkipCheckbox = canSkip && fieldGroupsLength <= 1

      return (
        <Block key={index} dataTestId="field-groups">
          <GroupHeader
            canSkip={showSkipCheckbox}
            currSkipped={formGroupSkipped}
            fieldGroupNumber={fieldGroupNumber}
            fieldGroupsLength={repeatable}
            formPath={formGroupName}
            isEditing={isEditing}
            label={label}
            prevSkipped={skipped}
            readOnly={readOnly}
            showDescription={showDescription}
          >
            {showDescription && description && (
              <Block
                backgroundColor={colors.gray.white}
                borderBottom={`1px solid ${colors.gray.lightest}`}
                fontWeight={300}
                fontSize="12px"
                marginLeft={-10}
                marginRight={-10}
                paddingBottom={10}
                paddingLeft={10}
                paddingRight={10}
              >
                {description}
              </Block>
            )}
            <Block
              background={colors.white}
              borderBottom={`1px solid ${colors.gray.lightest}`}
              paddingBottom="15px"
              paddingTop="15px"
            >
              <FieldSet paddingLeft="15px" paddingRight="15px">
                <FieldArray
                  name={`${fieldGroupPath}.fields`}
                  component={this.renderFields}
                  formGroupIndex={formGroupIndex}
                  fieldGroupIndex={index}
                  readOnly={readOnly}
                  showErrorOnUnTouched={showErrorOnUnTouched}
                  showWarnOnUnTouched={showWarnOnUnTouched}
                  skipped={skipped}
                  small={small}
                />
              </FieldSet>
              <Flex>
                {showAddAnotherButton && (
                  <Button onClick={() => this.createNewField(fields)}>
                    {t('button.addAnother')}
                  </Button>
                )}
                {handleDelete && (
                  <Button backgroundColor={colors.white} onClick={handleDelete}>
                    {t('button.remove')}
                  </Button>
                )}
              </Flex>
            </Block>
          </GroupHeader>
        </Block>
      )
    })

    return (
      <div>
        {showDefaultFormGroupHeader && defaultFormGroupHeader}
        {formFieldGroups}
        {showDefaultFormGroupHeader && (
          <Button onClick={() => this.createNewField(fields)}>
            {t('button.add')}
          </Button>
        )}
      </div>
    )
  }

  createNewField(fields) {
    const { templateEntity, headerFields, footerFields } = this.props

    //NOTE In header/footer we get the prefix 'header Fields/footerFields' those need to be removed
    //before cloning the template associated to them.
    const fieldName = fields.name.startsWith('footerFields')
      ? fields.name.replace('footerFields.', '')
      : fields.name.startsWith('headerFields')
      ? fields.name.replace('headerFields.', '')
      : fields.name

    const auditFields = fields.name.startsWith('headerFields')
      ? headerFields
      : fields.name.startsWith('footerFields')
      ? footerFields
      : templateEntity.template

    const template =
      templateEntity && templateEntity.template
        ? templateEntity.template
        : auditFields

    const newField = cloneDeep(first(get(template, fieldName)))

    newField._id = undefined

    each(newField.fields, field => {
      const { fieldtype, options = {} } = field
      // NOTE: do not reset display text values
      if (
        (fieldtype === 'text' && options.type === 'html') ||
        fieldtype === 'image-display'
      )
        return
      field.value = undefined
    })

    fields.push(newField)
  }

  renderFields({
    fieldGroupIndex,
    fields,
    formGroupIndex,
    readOnly,
    showErrorOnUnTouched,
    showWarnOnUnTouched,
    skipped,
    small,
  }) {
    const formFields = fields.map((fieldPath, index) => {
      const fieldValues = fields.get(index)

      const formField = this.renderField({
        fieldGroupIndex,
        fieldIndex: index,
        fieldPath,
        fieldValues,
        formGroupIndex,
        readOnly,
        showErrorOnUnTouched,
        showWarnOnUnTouched,
        skipped,
        small,
      })

      if (!formField) return null

      return (
        <Block key={index} dataTestId="field">
          {formField}
        </Block>
      )
    })

    return <Block dataTestId="fields">{formFields}</Block>
  }

  renderField({
    fieldGroupIndex,
    fieldIndex,
    fieldPath,
    fieldValues,
    formGroupIndex,
    readOnly,
    showErrorOnUnTouched,
    showWarnOnUnTouched,
    skipped,
    small,
  }) {
    const {
      applicationId,
      dataTestIdPrefix,
      form,
      getUserFullName,
      isEditing,
      t,
    } = this.props

    const dataTestId = dataTestIdPrefix
      ? `${dataTestIdPrefix}-form-${formGroupIndex}-fieldGroup-${fieldGroupIndex}-field-${fieldGroupIndex}`
      : `formGroup-${formGroupIndex}-fieldGroup-${fieldGroupIndex}-field-${fieldGroupIndex}`

    const name = `${fieldPath}.value`
    const { label, fieldtype, options = {} } = fieldValues
    const { format, multi, required, showOnRead = true, type } = options

    const validate = []

    if (!showOnRead && !isEditing) return null

    if (required) validate.push(isRequired)

    if (fieldtype === 'switch') {
      return (
        <Field
          component={InputCheckbox}
          dataTestId={dataTestId}
          name={name}
          label={label}
          readOnly={readOnly}
          required={null}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'number') {
      validate.push(isNumber)

      return (
        <Field
          component={InputNumber}
          dataTestId={dataTestId}
          name={name}
          label={label}
          normalize={normalizeNumber}
          readOnly={readOnly}
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'text' && type === 'html') {
      return (
        <Field
          alignTop
          component={InputWysiwyg}
          dataTestId={dataTestId}
          name={name}
          label={label}
          multiline={multi}
          readOnly
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'text' && type === 'signature') {
      // NOTE: we don't validate signatures as of yet
      // as the user has no UI to add a signature!
      return (
        <Field
          component={Signature}
          fieldPath={fieldPath}
          dataTestId={dataTestId}
          label={label}
          minHeight="150px"
          minWidth="270px"
          name={name}
          readOnly={readOnly}
          required={required}
          small={small}
          width={270}
        />
      )
    }

    if (fieldtype === 'text') {
      return (
        <Field
          component={InputText}
          dataTestId={dataTestId}
          name={name}
          label={label}
          multiline={multi}
          readOnly={readOnly}
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'select') {
      const validationOptions = get(fieldValues, 'validation.enum')
      const selectOptions = map(validationOptions, option => ({
        value: option,
        label: option,
      }))
      const isMultiSelect =
        type === 'select-multi' || type === 'select-multi-list'
      const isClearable = !isMultiSelect && !required

      return (
        <Field
          clearable={isClearable}
          component={InputSelect}
          dataTestId={dataTestId}
          label={label}
          multi={isMultiSelect}
          name={name}
          options={selectOptions}
          placeholder={t('placeholder.select')}
          readOnly={readOnly}
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showWarnOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'relationship') {
      return (
        <RelationshipField
          dataTestId={dataTestId}
          field={field}
          fieldPath={fieldPath}
          form={form}
          readOnly={readOnly}
          showErrorOnUnTouched
          showWarnOnUnTouched
          small={small}
        />
      )
    }

    if (fieldtype === 'file') {
      return (
        <FieldArray
          component={this.renderFieldFiles}
          dataTestIdPrefix={dataTestId}
          label={label}
          name={name}
          prefix={applicationId}
          readOnly={readOnly}
          required={required}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'list' && type === 'media') {
      return (
        <FieldArray
          component={this.renderFieldImages}
          dataTestIdPrefix={dataTestId}
          label={label}
          name={name}
          readOnly={readOnly}
          required={required}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'date') {
      return (
        <Field
          component={InputDate}
          dataTestId={dataTestId}
          dateFormat={format}
          name={name}
          label={label}
          normalize={normalizeDateTime}
          readOnly={readOnly}
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showErrorOnUnTouched}
          small={small}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'time') {
      return (
        <Field
          component={InputTime}
          dataTestId={dataTestId}
          name={name}
          label={label}
          normalize={normalizeDateTime}
          readOnly={readOnly}
          required={required}
          showErrorOnUnTouched={showErrorOnUnTouched}
          showWarnOnUnTouched={showErrorOnUnTouched}
          small={small}
          timeFormat={format}
          validate={validate}
        />
      )
    }

    if (fieldtype === 'image-display') {
      return (
        <Field
          component={InputDisplayImage}
          dataTestId={dataTestId}
          name={name}
          readOnly={true}
          required={false}
        />
      )
    }

    if (fieldtype === 'reference') {
      // TODO multi-select ready to go, change in future after mobile work is completely functional
      // const isMultiSelect = type === 'select-multi-list'
      const isMultiSelect = false

      return (
        <Field
          component={UsersSelect}
          label={label}
          multi={isMultiSelect}
          name={name}
          parse={value => {
            // NOTE It's important that multi select fields always return the
            // value as an array
            if (isMultiSelect || isArray(value)) {
              if (!isArray(value)) return []

              return value.map(val => {
                const fullName = getUserFullName(val)
                return {
                  id: val,
                  label: fullName || 'Unknown User',
                  type: 'user',
                }
              })
            }
            const fullName = getUserFullName(value)
            return {
              id: value,
              label: fullName || 'Unknown User',
              type: 'user',
            }
          }}
          format={value => {
            // NOTE It's important that multi select fields always return the
            // value as an array
            if (isMultiSelect || isArray(value)) {
              if (!isArray(value)) return []

              return value.map(val => {
                return val && val.id
              })
            }

            return value && value.id
          }}
          placeholder={
            isMultiSelect
              ? t('placeholder.selectUsers')
              : t('placeholder.selectUser')
          }
          readOnly={readOnly}
          required={required}
          small
          validate={validate}
        />
      )
    }

    return null
  }

  renderFieldFiles(props) {
    const {
      dataTestIdPrefix,
      fields,
      label,
      meta = {},
      prefix,
      readOnly,
      required,
      small,
    } = props

    // NOTE: use dirty field to determine if touched
    // in order to show validation message
    const { dirty, error } = meta

    const fileFields = fields.map((filePath, index) => (
      <Field
        component={File}
        dataTestId={`${dataTestIdPrefix}-file-${index}`}
        handleRemove={() => fields.remove(index)}
        key={index}
        name={filePath}
        readOnly={readOnly}
      />
    ))

    return (
      <Block marginBottom="15px">
        <InputGroup alignTop small={small}>
          <InputLabel
            alignTop
            label={label}
            required={required}
            small={small}
          />
          <Flex flexDirection="column" flexGrow={1}>
            {fileFields}
            {!readOnly && (
              <Upload
                accept={DOCUMENT_ACCEPT}
                contentTypes={DOCUMENT_CONTENT_TYPES}
                onError={handleError}
                onSuccess={files => map(files, file => fields.push(file))}
                tooltip={[
                  this.props.t(DOCUMENT_TOOLTIP[0]),
                  this.props.t(DOCUMENT_TOOLTIP[1]),
                ]}
              />
            )}
            {dirty && error && !readOnly && (
              <Block color={colors.red.normal} fontSize="10px" marginTop="10px">
                {error}
              </Block>
            )}
          </Flex>
        </InputGroup>
      </Block>
    )
  }

  renderFieldImages(props) {
    const {
      dataTestIdPrefix,
      fields,
      label,
      meta = {},
      readOnly,
      required,
      small,
    } = props

    // NOTE: use dirty field to determine if touched
    // in order to show validation message
    const { dirty, error } = meta

    const fieldImages = fields.map((imagePath, index) => (
      <Field
        component={Image}
        dataTestId={`${dataTestIdPrefix}-image-${index}`}
        handleRemove={() => fields.remove(index)}
        key={index}
        marginBottom="10px"
        minHeight="150px"
        minWidth="270px"
        name={imagePath}
        readOnly={readOnly}
        width={270}
      />
    ))

    return (
      <Block marginBottom="15px">
        <InputGroup small={small} alignTop>
          <InputLabel
            label={label}
            required={required}
            small={small}
            alignTop
          />
          <Flex flexDirection="column" flexGrow={1}>
            {fieldImages}
            {!readOnly && (
              <Upload
                accept={MEDIA_ACCEPT}
                maxFiles={MEDIA_MAX_FILES}
                contentTypes={MEDIA_CONTENT_TYPES}
                onError={handleError}
                onSuccess={results =>
                  map(results, file => fields.push(`${file.path}`))
                }
                tooltip={[
                  this.props.t(MEDIA_TOOLTIP[0]),
                  this.props.t(MEDIA_TOOLTIP[1]),
                ]}
              />
            )}
            {dirty && error && !readOnly && (
              <Block color={colors.red.normal} fontSize="10px" marginTop="10px">
                {error}
              </Block>
            )}
          </Flex>
        </InputGroup>
      </Block>
    )
  }
}

function handleError(error) {
  emitter.emit('notification:add', {
    message: error.message,
    theme: 'alert',
    title: 'Upload Error',
  })
}

function hasVisibleFieldGroups(fieldGroups) {
  return some(fieldGroups, ({ fields }) =>
    some(fields, ({ fieldtype, options }) => {
      const showField = get(options, 'showOnRead', true)
      return showField
    })
  )
}

function normalizeNumber(value) {
  return value ? value.toString() : value
}

function normalizeDateTime(input) {
  if (!input) {
    return ''
  }

  return moment(input)
    .utc()
    .format()
}

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )
  const { getUserFullName } = applicationUserSelectors
  return {
    getUserFullName,
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(TemplateEntry)
