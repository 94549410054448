import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import { Field, FieldArray } from 'redux-form'
import { validation } from '@lighthouse/sdk'
import i18next from 'i18next'
import emitter from 'utils/emitter'
import { colors } from 'config/theme'
import {
  InputGroup,
  InputMaterialCheckbox,
  InputLabel,
  InputSelect,
  InputText,
  Image,
} from 'components/form'
import Button from 'components/button'
import Icon from 'components/icon'
import { Flex, Block } from 'components/common'
import { Upload } from 'components/buttons'
import { TOOLTIPS } from 'config/constants'
import { useTranslation } from 'react-i18next'

const isRequired = validation.isRequired({
  errorMessage: i18next.t('validation.requiredField'),
})

export default function AuditItem(props) {
  const {
    fieldPath,
    formId,
    required,
    title,
    readOnly,
    followUpsEnabled,
    formValues,
    scores,
  } = props

  const { score } = formValues[fieldPath] || { score: '' }
  const { t } = useTranslation()
  const options = scores.map(mapScore)
  const isNewForm = !formId

  const {
    requiredQuestionOptions: { comment, assets, followUp } = {
      comment: false,
      assets: false,
      followUp: false,
    },
  } = options.find(option => option.value === score) || {}

  const [dropdown, setDropdown] = useState(comment || assets || followUp)

  const handleToggleExtras = () => {
    if (comment || assets || followUp) return
    setDropdown(!dropdown)
  }

  const renderFieldImages = useCallback(
    fieldProps => {
      const { fields, label, meta, readOnly, required, small } = fieldProps
      const { dirty, error } = meta
      const photoTooltip = [
        t(TOOLTIPS.photo.titleT),
        t(TOOLTIPS.photo.messageT),
      ]

      const fieldImages = fields.map((imagePath, index) => (
        <Field
          component={Image}
          handleRemove={() => fields.remove(index)}
          key={index}
          marginBottom="10px"
          minHeight="150px"
          minWidth="270px"
          name={imagePath}
          readOnly={readOnly}
          width={270}
        />
      ))

      return (
        <Block marginBottom="15px">
          <InputGroup small={small} alignTop>
            <InputLabel
              label={label}
              required={required}
              small={small}
              alignTop
            />
            <Flex flexDirection="column" flexGrow={1}>
              {fieldImages}
              {!readOnly && (
                <Upload
                  onError={handleError}
                  onSuccess={results =>
                    results.map(file => fields.push(`${file.path}`))
                  }
                  tooltip={photoTooltip}
                />
              )}
              {dirty && error && !readOnly && (
                <Block
                  color={colors.red.normal}
                  fontSize="10px"
                  marginTop="10px"
                >
                  {error}
                </Block>
              )}
            </Flex>
          </InputGroup>
        </Block>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!comment && !assets && !followUp) return setDropdown(false)
    if (comment || assets || followUp) return setDropdown(true)
  }, [comment, assets, followUp])

  const icon = dropdown ? 'neutral' : 'arrow-down'

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Block width={350}>
          <Field
            component={InputSelect}
            label={title}
            name={`${fieldPath}.score`}
            options={options}
            readOnly={readOnly}
            required={required}
            validate={required ? [isRequired] : []}
            placeholder={t('placeholder.selectAnOption')}
            small
          />
        </Block>
        {!dropdown && (
          <Button
            onClick={handleToggleExtras}
            height={40}
            marginBottom={23}
            marginRight={0}
            width={40}
            backgroundColor="#ffffff"
          >
            <Icon fontSize={20} fontWeight={400} lineHeight={1} name={icon} />
          </Button>
        )}
      </Flex>
      {dropdown && (
        <>
          {followUpsEnabled && isNewForm && (
            <Field
              dataAnalytics="follow-ups-entry-item-require-follow-up"
              disabled={followUp}
              component={InputMaterialCheckbox}
              name={`${fieldPath}.followUpTriggered`}
              label={t('labelRequireFollowUp')}
              overrideChecked={followUp}
              readOnly={readOnly}
              required={followUp}
              small
            />
          )}
          <Field
            component={InputText}
            multiline
            required={comment}
            validate={comment ? [isRequired] : []}
            name={`${fieldPath}.comments`}
            label={t('labelAddComment')}
            readOnly={readOnly}
            small
          />
          <FieldArray
            component={renderFieldImages}
            label={t('labelAttachPhoto')}
            name={`${fieldPath}.assets`}
            readOnly={readOnly}
            required={assets}
            validate={assets ? [isRequired] : []}
          />
        </>
      )}
    </>
  )
}

AuditItem.propTypes = {
  fieldPath: PropTypes.string.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string,
}

function mapScore(score) {
  return {
    requiredQuestionOptions: score.requiredQuestionOptions,
    label: score.label,
    value: score._id,
  }
}

function handleError(error) {
  emitter.emit('notification:add', {
    message: error.message,
    theme: 'alert',
    title: 'Upload Error',
  })
}
