import {
  attempt,
  chain,
  get,
  find,
  isArray,
  isEmpty,
  isError,
  omit,
  reduce,
  toNumber,
} from 'lodash'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import { getModule, validation } from '@lighthouse/sdk'
import {
  Field,
  getFormInitialValues,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
} from 'redux-form'
import { point } from '@turf/helpers'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import { Block } from 'components/common'
import TemplateEntry from 'components/templates/entry'
import withUrlQuery from 'components/with-url-query'
import FlagsHOC from 'components/flags/hoc'

import {
  UsersSelect,
  BasicForm,
  FieldGroup,
  FieldSet,
  InputLocationSelect,
  InputSelect,
  InputText,
  StatusSelect,
} from 'components/form'

import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
  handleDeleteResponse,
} from 'components/form/helpers'

import { Crud } from 'components/controls'
import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'
import geocoder from 'utils/geocoder'

import Timeline from './components/timeline'
import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import TitleDivider from '../../../title-divider'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const LIST_ID = 'all'

const areaModule = getModule('areas')
const issueModule = getModule('issues')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

export default compose(
  reduxForm(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlQuery,
  withProps(buildFormProps),
  withHandlers({ deleteIssue, saveIssue }),
  lifecycle({ componentDidUpdate }),
  FlagsHOC
)(Form)

function Form(props) {
  const {
    availableFloors,
    deleteIssue,
    dirty,
    error,
    errorCount,
    flags,
    form,
    formValues,
    handleCancel,
    handleEdit,
    handleSubmit,
    id,
    initialValues: { timeline } = {},
    invalid,
    isEditing,
    isLocked,
    readOnly,
    reset: handleReset,
    saveIssue,
    setEditing,
    submitFailed,
    submitting,
    templateEntity,
  } = props

  const { editFormTitles } = flags

  const { t } = useTranslation()

  const isExistingIssue = !!id

  useEffect(() => {
    if (dirty && setEditing) {
      setEditing(true)
    }
  }, [dirty, setEditing])

  const floorOptions = chain(availableFloors)
    .map(floor => ({
      value: floor.level,
      label: floor.label,
    }))
    .sortBy('value')
    .value()

  const hasFloorOptions = !isEmpty(floorOptions)
  const showErrorOnUnTouched = isEditing && isExistingIssue

  return (
    <BasicForm noValidate>
      <FieldWrapper>
        {error && submitFailed && (
          <Block paddingTop={10}>
            <Alert type="error" messages={[error]} />
          </Block>
        )}
        <FieldGroup>
          <FieldSet>
            <Field
              component={InputText}
              disabled={!editFormTitles}
              label={t('labelTitle')}
              name="title"
              placeholder={t('placeholder.issueTitle')}
              readOnly={readOnly}
              required
              small
              validate={[isRequired]}
            />
            {isExistingIssue && !isEditing && (
              <>
                <Field
                  component={InputText}
                  label={t('labelCreated')}
                  name="created"
                  readOnly
                  small
                />
                <Field
                  component={InputText}
                  label={t('labelLocation')}
                  name="location"
                  readOnly
                  small
                />
              </>
            )}
            {isEditing && (
              <Field
                component={InputLocationSelect}
                label={t('labelLocation')}
                mapTargetMode
                name="locationSelect"
                placeholder={t('placeholder.selectOrDropPin')}
                required
                resource="issue"
                small
                validate={[isRequired]}
              />
            )}
            <Field
              component={UsersSelect}
              label={t('labelAssignees')}
              multi
              name="assignees"
              normalize={value => (isArray(value) ? value : [])}
              placeholder={t('placeholder.selectAssignedUsers')}
              readOnly={readOnly}
              small
            />
            <Field
              clearable={false}
              component={StatusSelect}
              label={t('labelStatus')}
              name="status"
              placeholder={t('placeholder.selectIssueStatus')}
              readOnly={readOnly}
              required
              small
              validate={[isRequired]}
            />
            <Field
              component={InputSelect}
              disabled={!hasFloorOptions}
              label={t('labelFloors')}
              multi
              name="floorsRef"
              normalize={value => (isArray(value) ? value : [])}
              options={floorOptions}
              placeholder={t('placeholder.floors')}
              readOnly={readOnly}
              required={false}
              small
              shouldSort={false}
            />
          </FieldSet>
        </FieldGroup>
        {isExistingIssue && timeline && (
          <Block>
            <TitleDivider title="Issue Timeline" />
            <Timeline timeline={timeline} />
          </Block>
        )}
        <TemplateEntry
          form={form}
          formValues={formValues}
          isEditing={isEditing}
          readOnly={readOnly}
          showErrorOnUnTouched={showErrorOnUnTouched}
          small
          templateEntity={templateEntity}
        />
      </FieldWrapper>
      <ControlWrapper>
        <ButtonGroup align="left">
          <Crud
            deleteModalMessage={t('modal.delete.issue.message')}
            deleteModalTitle={t('modal.delete.issue.title')}
            dirty={dirty}
            errorCount={errorCount}
            handleCancel={handleCancel}
            handleDelete={deleteIssue}
            handleEdit={handleEdit}
            handleSave={handleSubmit(saveIssue)}
            id={id}
            invalid={invalid}
            isEditing={isEditing}
            isLocked={isLocked}
            permissionModule="issue"
            reset={handleReset}
            small
            submitting={submitting}
          />
        </ButtonGroup>
      </ControlWrapper>
    </BasicForm>
  )
}

function buildFormProps(props) {
  const { findEnclosing, formSyncErrors, formValues = {} } = props

  let availableFloors = []
  let enclosingBuilding = null
  const formGeometry = formValues.geometry

  if (formGeometry) {
    enclosingBuilding = findEnclosing(formGeometry, 'building')
    availableFloors = get(enclosingBuilding, 'entity.floors', [])
  }

  const errorCount = getErrorCount(formSyncErrors)

  return {
    availableFloors,
    enclosingBuilding,
    errorCount,
  }
}

async function componentDidUpdate(prevProps) {
  const {
    change,
    findEnclosing,
    formInitialValues,
    history,
    pristine,
    urlQuery,
  } = this.props

  const {
    enclosingBuilding: prevEnclosingBuilding,
    pristine: prevPristine,
    urlQuery: { lat: prevLat, lng: prevLng },
  } = prevProps

  const initialLat = get(formInitialValues, 'gps.geometry.coordinates.1')
  const initialLng = get(formInitialValues, 'gps.geometry.coordinates.0')

  // NOTE: the form has been reset, if we have existing gps values reset the
  // url query to reset the position of the icon on the map
  if (pristine && !prevPristine) {
    if (initialLat && initialLng) {
      const nextSearch = queryString.stringify({
        ...urlQuery,
        lat: initialLat,
        lng: initialLng,
      })

      history.push({ search: `?${nextSearch}` })
    } else {
      const nextQuery = omit(urlQuery, ['lat', 'lng'])
      const nextSearch = queryString.stringify(nextQuery)

      history.push({ search: `?${nextSearch}` })
    }

    return
  }

  const { lat, lng } = urlQuery

  const isInitialLat = lat === String(initialLat)
  const isInitialLng = lng === String(initialLng)

  // NOTE: prevent any further changes if form reset
  if (isInitialLat && isInitialLng) return

  const hasLatChanged = prevLat !== lat
  const hasLngChanged = prevLng !== lng

  if (!hasLatChanged && !hasLngChanged) return

  const lngLat = [toNumber(lng), toNumber(lat)]
  const geoJsonPoint = attempt(point, lngLat)
  const validPoint = !isError(geoJsonPoint)

  const geometry = validPoint ? geoJsonPoint.geometry : null

  if (!geometry) return

  const enclosingLocation = findEnclosing(geometry, 'location')
  const enclosingBuilding = findEnclosing(geometry, 'building')
  const availableFloors = get(enclosingBuilding, 'entity.floors', [])
  const hasChangedBuilding = prevEnclosingBuilding !== enclosingBuilding

  // NOTE: remove floorsRef value if no available floors or building has
  // changed, this will occur when the marker has been moved
  if (hasChangedBuilding || isEmpty(availableFloors)) {
    change('floorsRef', [])
  }

  if (enclosingLocation) {
    // NOTE: we've moved to a new location
    const label = enclosingLocation.entity.name
    const value = enclosingLocation.id

    change('locationSelect', { label, value })
  } else {
    // NOTE: we've moved outside of a location
    const label = await getReverseGeoCoded({ lat, lng })
    const value = 'custom-value'

    change('locationSelect', { label, value })
  }

  change('geometry', geometry)
}

function deleteIssue(props) {
  const { deleteIssue, id, onClose, setDeleting, setError } = props

  return () => {
    // NOTE: no need to set this back to
    // false as if error, panel will show
    // error and remove form
    setDeleting(true)

    return deleteIssue(id)
      .then(handleDeleteResponse('issue'))
      .then(onClose)
      .catch(error => setError(error))
  }
}

async function getReverseGeoCoded({ lat, lng }) {
  const response = await geocoder.reverse({ lat, lon: lng })

  const { address } = response

  const LABEL_PARTS = [
    ['name', 'houseNumber', 'viewpoint', 'road'],
    ['city', 'suburb', 'neighbourhood'],
    ['state', 'county', 'stateDistrict', 'country'],
  ]

  const allParts = reduce(
    LABEL_PARTS,
    (accum = [], fieldArr) => {
      const partField = find(fieldArr, field => address[field])
      const value = address[partField]
      if (value) accum.push(value)
      return accum
    },
    []
  )

  const longParts = allParts
  const reverse = longParts.join(', ')

  return reverse
}

function saveIssue(props) {
  const { id, hasGeo, onClose, saveIssue, userId } = props

  return formValues => {
    const {
      assignees,
      floorsRef,
      formGroups,
      geometry,
      status,
      template,
      title,
    } = formValues

    // NOTE: issue schema expects the floors ref to be at the root of the
    // document
    const payload = {
      assignees,
      entry: { formGroups },
      floorsRef,
      gps: { geometry },
      status,
      template,
      title,
    }

    // NOTE: if we originally had a geo property update
    if (hasGeo) {
      payload.geo = geometry
    }

    // NOTE: if new, add user id
    if (!id) {
      payload.user = userId
    } else {
      // NOTE: We have to unset location when editing in case new gps coordinates are not inside of
      // the previous location (legacy location id)
      payload.location = null
    }

    return saveIssue(id, payload)
      .then(handleSaveResponse('issue'))
      .then(onClose)
      .catch(handleFormError)
  }
}

function mapStateToProps(state, props) {
  const { form } = props

  const areaSelectors = areaModule.selectors(state)(LIST_ID)
  const findEnclosing = areaSelectors.findEnclosing

  const formInitialValues = getFormInitialValues(form)(state)
  const formSyncErrors = getFormSyncErrors(form)(state)
  const formValues = getFormValues(form)(state)
  const userId = state.user.data._id

  return {
    findEnclosing,
    formInitialValues,
    formSyncErrors,
    formValues,
    userId,
  }
}

function mapDispatchToProps(dispatch, props) {
  const { form } = props

  return {
    deleteIssue: id => dispatch(issueModule.remove(id)),
    saveIssue: (id, payload) => dispatch(issueModule.save({}, payload, id)),
  }
}
