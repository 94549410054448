import 'react-day-picker/lib/style.css'
import './styles.css'

import { assign, get, isBoolean, isEqual, isPlainObject, map } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import DayPicker, { DateUtils } from 'react-day-picker'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import moment from 'moment'
import Radium from 'radium'
import React, { Component } from 'react'
import SelectBox from 'react-select'

import { Flex } from 'components/common'
import Button from 'components/button'
import TimePicker from 'components/time-picker'
import TimePickerField from 'components/time-picker/components/time-picker-field'

import styles from './styles'
import { withTranslation } from 'react-i18next'

const ALL_DAY = 'all-day'
const START_END = 'start-end'
const SECOND = 59
const MSECOND = 999

// Options for time selector
const timeOptions = [
  {
    label: 'All day',
    labelT: 'labelAllDay',
    value: ALL_DAY,
  },
  {
    label: 'Start and end time',
    labelT: 'labelStartEndTime',
    value: START_END,
  },
]

const timeFormat = 'h:mm A'
const defaultTimes = {
  start: '12:00 AM',
  end: '11:59 PM',
}

class DateTimePicker extends Component {
  static propTypes = {
    hoverRange: PropTypes.array,
    position: PropTypes.oneOf(['tl', 'tr', 'br', 'bl']),
  }

  state = {
    open: false,
    timeOption: ALL_DAY,
    times: defaultTimes,
  }

  componentWillMount() {
    this.toggle = this.toggle.bind(this)
    const { value } = this.props
    const isRangeWithValue = this.isRange() && this.hasValue()

    if (!isRangeWithValue) return this.setDate(value)

    // if value is a range then process start/end times

    const startTime = moment(value.from).format(timeFormat)
    const endTime = moment(value.to).format(timeFormat)

    if (
      startTime === defaultTimes.startTime &&
      endTime === defaultTimes.endTime
    ) {
      // just set date value if pre-filled value is all-day
      return this.setDate(value)
    }

    // otherwise set custom times...
    return this.setState({
      times: {
        start: startTime,
        end: endTime,
      },
      timeOption: START_END,
    })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (!isEqual(prevProps.value, value)) {
      this.setDate(value)
    }
  }

  /**
   * Sets the date to the local state of the component
   */
  setDate(date, callback) {
    let value

    if (!date) return

    if (isPlainObject(date)) {
      const from = date.from ? moment(date.from).toDate() : null

      const to = date.to ? moment(date.to).toDate() : null

      value = { from, to }
    } else {
      value = moment(date).toDate()
    }

    this.setState({ value }, callback)
  }

  // Toggle open/close state of date selector
  toggle(value) {
    // set to value provided or whatever current state is not
    const open = isBoolean(value) ? value : !this.state.open
    this.setState({ open })
  }

  handleClickOutside() {
    this.cancel()
    this.toggle(false)
  }

  // Check if single date value or range
  isRange() {
    return isPlainObject(this.state.value)
  }

  hasValue() {
    const { value } = this.state
    if (this.isRange()) {
      return value.from || value.to
    }
    return value
  }

  // Change from and to strings to dates
  processValue() {
    const { value } = this.state

    if (this.isRange(value)) {
      const from = value.from && new Date(value.from)
      const to = value.to && new Date(value.to)
      return { from, to }
    }

    return value && new Date(value)
  }

  modifiers() {
    const { allowAllDates, hoverRange, timezone } = this.props

    // NOTE please note when calculating these values
    // - active range is the date range the user selects
    // - selectable range is the date range the user can select from

    const value = this.processValue()
    const isActiveRangeEnd = this.isActiveRangeEnd.bind(this)
    const isAfterActiveRange = this.isAfterActiveRange.bind(this)
    const isBeforeSelectableRange = this.isBeforeSelectableRange.bind(this)
    const isDisabledDay = this.isDisabledDay.bind(this)
    const isSelectedDay = this.isSelectedDay.bind(this)
    const isToday = this.isToday.bind(this)

    const disabled = day => isDisabledDay(day)
    const activeRangeEnd = day => isActiveRangeEnd(day)
    const beforeSelectableRange = day => isBeforeSelectableRange(day)
    const afterActiveRange = day => isAfterActiveRange(day)
    const selected = day => isSelectedDay(day)
    const today = day => isToday(day)

    return {
      activeRangeEnd,
      afterActiveRange,
      beforeSelectableRange,
      disabled,
      hoverRange,
      selected,
      today,
    }
  }

  isDisabledDay(day) {
    const {
      allowAllDates,
      disablePastDates,
      minDate,
      maxDate,
      timezone,
    } = this.props

    if (allowAllDates) return false

    const nowDateTimezone = moment().tz(timezone)
    const localTime = moment(day).format('YYYY-MM-DD HH:mm')
    const dayTimezoneTime = moment.tz(localTime, timezone)
    const isBeforeNowDate = dayTimezoneTime.isBefore(nowDateTimezone, 'day')

    if (disablePastDates) {
      return isBeforeNowDate
    }

    const isBeforeMinDate = dayTimezoneTime.isBefore(minDate, 'day')
    const isAfterMaxDate = dayTimezoneTime.isAfter(maxDate, 'day')

    if (minDate && maxDate) {
      return isBeforeMinDate || isAfterMaxDate
    }

    if (minDate) {
      return isBeforeMinDate
    }

    if (maxDate) {
      return isAfterMaxDate
    }

    const isAfterNowDate = dayTimezoneTime.isAfter(nowDateTimezone, 'day')

    return isAfterNowDate
  }

  isActiveRangeEnd(day) {
    const { allowAllDates, maxRange, timezone } = this.props

    // NOTE if allow all or no max range value show all
    if (allowAllDates || !maxRange) return false

    const value = this.processValue()

    const selectedDay = this.isRange() ? value.from : value

    const mDay = moment(day)
    const maxDate = moment(selectedDay).add(maxRange, 'months')

    const isSameAsMaxDate = mDay.isSame(maxDate, 'day')

    return isSameAsMaxDate
  }

  isAfterActiveRange(day) {
    const { allowAllDates, maxRange } = this.props

    // NOTE if allow all or no max range value show all
    if (allowAllDates || !maxRange) return false

    const value = this.processValue()

    const selectedDay = this.isRange() ? value.from : value

    const mDay = moment(day)
    const maxDate = moment(selectedDay).add(maxRange, 'months')
    const today = new Date()

    const isAfterMaxDate = mDay.isAfter(maxDate, 'day')
    const isBeforeNowDate = mDay.isBefore(today, 'day')
    const isSameAsNowDate = mDay.isSame(today, 'day')

    return (isBeforeNowDate || isSameAsNowDate) && isAfterMaxDate
  }

  isBeforeSelectableRange(day) {
    const { allowAllDates, selectableRange, timezone } = this.props

    // NOTE if allow all dates or no selectable range value show all
    if (allowAllDates || !selectableRange) return false

    const localTime = moment(day).format('YYYY-MM-DD HH:mm')
    const dayTimezoneTime = moment.tz(localTime, timezone)
    const minDateTimezone = moment()
      .tz(timezone)
      .subtract(selectableRange, 'month')

    const isBeforeMinDate = dayTimezoneTime.isBefore(minDateTimezone, 'day')
    return isBeforeMinDate
  }

  isSelectedDay(day) {
    const value = this.processValue()

    const isSelected = this.isRange()
      ? DateUtils.isDayInRange(day, value)
      : DateUtils.isSameDay(day, value)

    return isSelected
  }

  isToday(day) {
    const { timezone } = this.props

    const localTime = moment(day).format('YYYY-MM-DD HH:mm')
    const dayTimezoneTime = moment.tz(localTime, timezone)
    const nowDateTimezone = moment().tz(timezone)
    const isToday = dayTimezoneTime.isSame(nowDateTimezone, 'day')

    return isToday
  }

  // Handle click on date selector
  handleDayClick(day) {
    const isDisabledDay = this.isDisabledDay(day)
    const isBeforeSelectableRange = this.isBeforeSelectableRange(day)
    const isAfterActiveRange = this.isAfterActiveRange(day)

    if (isDisabledDay || isBeforeSelectableRange || isAfterActiveRange) return

    const value = this.processValue()
    const isRange = this.isRange()

    let newValue = isRange ? handleRangeChange(value, day) : day

    // Add times to date selection
    if (isRange && newValue && newValue.from && newValue.to) {
      newValue = this.appendTimes(newValue)
    }

    const nextAction =
      !isRange && this.props.autoClose ? this.confirmDate : () => {}
    this.setDate(newValue, nextAction)
  }

  /**
   * Confirms the date from local state to the onChange handler
   */
  confirmDate(e) {
    if (e) e.stopPropagation()
    this.toggle(false)
    this.props.onChange(this.state.value)
  }

  // Cancel should revert state values to props values
  cancel(e) {
    if (e) e.stopPropagation()
    this.setDate(this.props.value)
    this.toggle(false)
  }

  // Clear should wipe values set
  clear(e) {
    if (e) e.stopPropagation()

    const value = this.isRange() ? { from: null, to: null } : null

    this.setDate(value)
  }

  // Append time values to date stamp when not full-day selection
  appendTimes(value) {
    if (!value || !value.from || !value.to) return value

    const { times } = this.state

    const from = moment(times.start, timeFormat)
    const to = moment(times.end, timeFormat)
    const isFullDay = this.state.timeOption === ALL_DAY

    const newValue = {
      from: moment(value.from)
        .hour(from.hour())
        .minute(from.minute())
        .toDate(),
      to: moment(value.to)
        .hour(to.hour())
        .minute(to.minute())
        .second(isFullDay ? SECOND : 0)
        .millisecond(isFullDay ? MSECOND : 0)
        .toDate(),
    }

    return newValue
  }

  // Change date picker to include start and end times
  timeSelect({ value }) {
    this.setState({ timeOption: value })

    if (value === ALL_DAY) {
      this.processTimesChange(defaultTimes)
    }
  }

  //  Update selected times
  changeTime(id) {
    return value => {
      const newTimes = assign({}, this.state.times, {
        [id]: value,
      })
      this.processTimesChange(newTimes)
    }
  }

  processTimesChange(times) {
    this.setState(
      {
        times,
      },
      () => {
        const value = this.appendTimes(this.state.value)
        this.setDate(value)
      }
    )
  }

  render() {
    const {
      allowAllDates,
      autoClose,
      children,
      disabled,
      disableTimepicker,
      maxRange,
      minDate,
      minDateText,
      numberOfMonths,
      onDayMouseEnter,
      onDayMouseLeave,
      position = 'bl',
      selectableRange,
      selectedRange,
      width = 'auto',
      t,
      toMonth,
    } = this.props

    const { open, times, timeOption } = this.state

    const isRange = this.isRange.bind(this)()
    const hasValue = this.hasValue()
    const value = this.processValue()

    const numberOfMonthsCount = numberOfMonths || (isRange ? 2 : 1)
    const datepickerStyles = [
      styles.datepicker,
      styles.position[position],
      {
        width: numberOfMonthsCount * 255,
      },
    ]

    // NOTE this only gets set on first render
    let initialMonth = new Date()
    if (selectedRange && selectedRange.length > 0) {
      initialMonth = selectedRange[0]
    } else if (hasValue && isRange) {
      initialMonth = value.from
    } else if (hasValue) {
      initialMonth = value
    }

    const showTimepicker = !disableTimepicker && timeOption !== ALL_DAY
    const hasDateRestrictions =
      (!allowAllDates && selectableRange) || maxRange || minDate
    const monthVisibleText = selectableRange > 1 ? 'months' : 'month'
    const maxRangeText = maxRange > 1 ? 'months' : 'month'

    const cancelButton = (
      <Button
        dataTestId="datePickerCancelBtn"
        onClick={this.cancel.bind(this)}
        style={styles.actionButton}
      >
        {t('button.cancel')}
      </Button>
    )

    const clearButton = (
      <Button
        dataTestId="datePickerClearBtn"
        onClick={this.clear.bind(this)}
        style={styles.actionButton}
      >
        {t('button.clear')}
      </Button>
    )

    const showClear = isRange && hasDateRestrictions && hasValue
    const showCancel = !isRange ? true : hasDateRestrictions ? !hasValue : true

    return (
      <div
        className="lh-date-time-picker"
        data-testid="datePickerToggle"
        ref="root"
        onMouseLeave={this.props.enableOnClickOutside}
        onClick={() => !disabled && this.toggle(true)}
        style={[styles.root, { width }]}
      >
        {children}
        {open && (
          <div data-testid="datePicker" style={datepickerStyles}>
            <DayPicker
              ref="daypicker"
              initialMonth={initialMonth}
              numberOfMonths={numberOfMonths || (isRange ? 2 : 1)}
              modifiers={this.modifiers.bind(this)()}
              onDayClick={this.handleDayClick.bind(this)}
              selectedDays={selectedRange}
              onDayMouseEnter={onDayMouseEnter}
              onDayMouseLeave={onDayMouseLeave}
              toMonth={toMonth}
            />
            {hasDateRestrictions && (
              <Flex
                marginBottom={10}
                marginLeft={21}
                marginRight={21}
                fontSize="12px"
              >
                {!hasValue &&
                  selectableRange &&
                  `Historical data is limited to the previous ${selectableRange} ${monthVisibleText}. Please contact your Customer Success Manager if you require data prior to this.`}
                {hasValue &&
                  maxRange &&
                  `Date range queries are limited to ${maxRange} ${maxRangeText}`}
                {hasValue && minDate && minDateText}
              </Flex>
            )}
            <Flex
              justifyContent="flex-end"
              marginBottom={20}
              marginLeft={20}
              marginRight={20}
            >
              {!disableTimepicker && (
                <div style={styles.timesWrapper}>
                  <div style={styles.select}>
                    <SelectBox
                      value={timeOption}
                      disabled={!hasValue}
                      clearable={false}
                      onFocus={this.props.disableOnClickOutside}
                      onChange={this.timeSelect.bind(this)}
                      options={map(timeOptions, ({ label, labelT, value }) => ({
                        label: labelT ? t(labelT) : label,
                        value,
                      }))}
                    />
                  </div>
                </div>
              )}
              {!autoClose && (
                <Flex>
                  <Button
                    dataTestId="datePickerUpdateBtn"
                    disabled={!hasValue}
                    onClick={this.confirmDate.bind(this)}
                    style={styles.actionButton}
                    theme="primary"
                  >
                    {t('button.update')}
                  </Button>
                  {showCancel && cancelButton}
                  {showClear && clearButton}
                </Flex>
              )}
            </Flex>
            {hasValue && showTimepicker && (
              <Flex alignItems="center" margin="10px 20px">
                <TimePicker
                  onChange={this.changeTime.bind(this)('start')}
                  value={times.start}
                >
                  {({ handleChange, hour, meridiem, minute }) => (
                    <Flex flexDirection="row">
                      <TimePickerField
                        onChange={handleChange}
                        type="hour"
                        value={hour}
                        validationRule={/^(1[0-2]|[1-9])$/}
                      />
                      <TimePickerField
                        onChange={handleChange}
                        type="minute"
                        value={minute}
                        validationRule={/^[0-5]?[0-9]$/}
                      />
                      <TimePickerField
                        onChange={handleChange}
                        type="meridiem"
                        value={meridiem}
                        validationRule={/^(AM|am|PM|pm)$/}
                      />
                    </Flex>
                  )}
                </TimePicker>
                <span style={[styles.to]}>to</span>
                <TimePicker
                  onChange={this.changeTime.bind(this)('end')}
                  value={times.end}
                >
                  {({ handleChange, hour, meridiem, minute }) => (
                    <Flex flexDirection="row">
                      <TimePickerField
                        onChange={handleChange}
                        type="hour"
                        value={hour}
                        validationRule={/^(1[0-2]|[1-9])$/}
                      />
                      <TimePickerField
                        onChange={handleChange}
                        type="minute"
                        value={minute}
                        validationRule={/^[0-5]?[0-9]$/}
                      />
                      <TimePickerField
                        onChange={handleChange}
                        type="meridiem"
                        value={meridiem}
                        validationRule={/^(AM|am|PM|pm)$/}
                      />
                    </Flex>
                  )}
                </TimePicker>
              </Flex>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  onClickOutside,
  Radium
)(DateTimePicker)

function handleRangeChange(currentRange, day) {
  let newRange
  let { from, to } = currentRange

  const hasFrom = from
  const hasTo = to
  const mDay = moment(day)
  const mFrom = moment(from)
  const mTo = moment(to)

  // Set the dates to the same time of day so the date comparison
  // behaves predictably. We might receive dates that are the same day
  // but different times, which doens't make sense in the context
  // of a datepicker.
  from = hasFrom && mFrom.startOf('day').toDate()
  to = hasTo && mTo.startOf('day').toDate()

  const isRange = hasFrom && hasTo

  const isEmpty = !hasFrom && !hasTo
  const isPastDay = isRange && mDay.isBefore(mFrom)
  const isSameFromDay = isRange && DateUtils.isSameDay(from, day)
  const isSameToDay = isRange && DateUtils.isSameDay(to, day)
  const isSameDay = isSameFromDay && isSameToDay
  const isInRange = isRange && DateUtils.isDayInRange(day, currentRange)

  // NOTE when user selects the same day clear values
  if (isSameDay) {
    return {
      from: null,
      to: null,
    }
  }

  // NOTE following scenarios are covered:
  // no dates are set
  // date selected is in the past to current set date
  // date selected is within the current range
  if (isEmpty || isPastDay || isInRange) {
    return {
      from: day,
      to: day,
    }
  }

  // NOTE user selected a day outside of current range
  return DateUtils.addDayToRange(day, currentRange)
}

function mapStateToProps(state) {
  return {
    timezone: get(state, 'app.timezone', null),
  }
}
