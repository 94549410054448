import React from 'react'
import { Base } from '../'

export default function Block(props) {
  const {
    children,
    dataAnalytics,
    dataTestId,
    onClick,
    onKeyDown,
    ...componentProps
  } = props

  return (
    <Base
      cursor={onClick ? 'pointer' : 'inherit'}
      dataAnalytics={dataAnalytics}
      dataTestId={dataTestId}
      display="block"
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...componentProps}
    >
      {children}
    </Base>
  )
}
